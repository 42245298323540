import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

function MetaTags() {
    const location = useLocation();
    const currentUrl = `https://hobs.space${location.pathname}`;

    return (
        <Helmet>
            <meta property="og:url" content={currentUrl} />
        </Helmet>
    );
}

export default MetaTags;
