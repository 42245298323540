import React from 'react';
import SectionButtonAddSocial from '../../components/SectionButtonAdd/SectionButtonAddSocial';
import YtbColor from "../../assets/youtube-color.png";
import Linkedin from "../../assets/linkedin-color.png";
import X from "../../assets/x.png";
import Tiktok from "../../assets/tiktok-color.png";
import Insta from "../../assets/insta-color.png";
import Facebook from "../../assets/facebook-color.png";
import QRCode from "../../assets/qrcode.png";
import Globe from "../../assets/globe.png";
import AppleMusic from "../../assets/applemusic-color.png";
import Spotify from "../../assets/spotify-color.png";
import Deezer from "../../assets/deezer-color.png";
import Twitch from "../../assets/twitch-color.png";
import YoutubeMusic from "../../assets/youtubemusic-color.png";
import { useUser } from '../../utils/contexts/userContext';

const AddSocial = (props) => {
  const {user} = useUser();

  const linkIsActive = (name) => {
    return user.links.some(link => link.name === name);
  };

  return (
    <div className='flex flex-col justify-start items-start p-10 bg-slate-200 overflow-y-scroll scrollbarhide h-full' style={{ width: "100%", height: "100%" }}>
      <div className='flex flex-row justify-center items-center gap-3 pb-8 w-fit'>
        <button className='flex justify-center items-center' onClick={props.setOpenModal}>
          <i style={{ color: "#000", fontSize: "22px" }} className="fa-solid fa-arrow-left"></i>
        </button>
        <p className='text-xl text-black font-bold'>Ajoutez des médias sociaux à votre page</p>
      </div>

      <SectionButtonAddSocial
        title="Réseaux sociaux"
        items={[
          { imgSrc: Facebook, title: 'Facebook', onClick: () => props.addComponent('facebook'), active: linkIsActive("facebook") },
          { imgSrc: Insta, title: 'Instagram', onClick: () => props.addComponent('instagram'), active: linkIsActive("instagram") },
          { imgSrc: Linkedin, title: 'Linkedin', onClick: () => props.addComponent('linkedin'), active: linkIsActive("linkedin") },
          { imgSrc: Tiktok, title: 'Tiktok', onClick: () => props.addComponent('tiktok'), active: linkIsActive("tiktok") },
          { imgSrc: X, title: 'X (Twitter)', onClick: () => props.addComponent('x'), active: linkIsActive("x") },
        ]}
      />
      <SectionButtonAddSocial
        title="Streaming"
        items={[
          { imgSrc: AppleMusic, title: 'Apple Music', onClick: () => props.addComponent('applemusic'), active: linkIsActive("applemusic") },
          { imgSrc: Deezer, title: 'Deezer', onClick: () => props.addComponent('deezer'), active: linkIsActive("deezer") },
          { imgSrc: Spotify, title: 'Spotify', onClick: () => props.addComponent('spotify'), active: linkIsActive("spotify") },
          { imgSrc: Twitch, title: 'Twitch', onClick: () => props.addComponent('twitch'), active: linkIsActive("twitch") },
          { imgSrc: YtbColor, title: 'Youtube', onClick: () => props.addComponent('youtube'), active: linkIsActive("youtube") },
          { imgSrc: YoutubeMusic, title: 'Youtube Music', onClick: () => props.addComponent('youtubemusic'), active: linkIsActive("youtubemusic") },
        ]}
      />
      <SectionButtonAddSocial
        title="Autre"
        items={[
          { imgSrc: Globe, title: 'Site web', onClick: () => props.addComponent('site web'), active: linkIsActive("site web") },
          { imgSrc: QRCode, title: 'QR Code', onClick: () => props.addComponent('qrcode'), active: linkIsActive("qrcode") },
        ]}
      />
    </div>
  );
};

export default AddSocial;
