import React, { useState, useEffect } from 'react'
import styles from './IndividualVideo.module.css';
import Title from '../Title/Title';
import Slider from '../Slider/Slider';
import OneVideo from '../OneVideo/OneVideo';
import { useHobspace } from '../../../utils/contexts/hobspaceContext';

function IndividualVideo(props) {
    const {hobspace} = useHobspace();
    const [components, setComponents] = useState([]);

    useEffect(() => {
        const components = props.components.map((c, index) => (
            <OneVideo key={index} id={c.id} idMedia={c.idMedia} description={props.components.length < 2 ? c.description : false} visibility={c.visibility} />
        ));
        setComponents(components);
    }, [props.components]); 
 
    return (
        <div className={styles.IndividualVideo}>
            {props.title &&
                <Title id={props.ancre} color={hobspace.colorFont} title={props.title} marginBottom={true} />
            }
            {components.length > 0 && components.length < 2 && components.map((component, index) => (
                <React.Fragment key={index}>
                    {component}
                </React.Fragment>
            ))}
            {components.length >= 2 && 
                <Slider components={components} />   
            }
        </div>
    );
}

export default IndividualVideo;