import React, {useState, useEffect} from 'react'
import './Pricing.css';
import NavigationHome from '../../Layouts/Navigation/NavigationHome/NavigationHome';
import PricingPack from '../../components/PricingPack/PricingPack';
import FAQ from '../../Layouts/FAQ/FAQ';
import Footer from '../../Layouts/Footer/Footer';
import GetStarted from '../../Layouts/GetStarted/GetStarted';
import TitleTabNavigator from '../../components/TitleTabNavigator/TitleTabNavigator';
import MetaTags from '../../components/MetaTags/MetaTags';

function Pricing() {

    const bgdContainer = "#ffffff";
    const colorFont = "255, 255, 255";
    const [activeButton, setActiveButton] = useState(true);
    const [pricingDate, setPricingDate] = useState(true);

    const handleButtonClick = (title) => {
        setActiveButton(title);
    };

    useEffect(() => {
       
    }, []);
 
    return (
        <div className='Pricing' style={{background: bgdContainer}}>
            <MetaTags/>
            <TitleTabNavigator title={"Tarifs"}/>
            <NavigationHome color={colorFont} bgd={bgdContainer} />
            <div className='containerTitlePricing'>
                <h1 className='titlePricing'>Une tarification simple et transparente</h1>
                <p className='subtitlePricing'>Que vous soyez un créateur, un entrepreneur individuel, une petite entreprise ou une agence, nous vous couvrons. Démarrez dès aujourd'hui l'un de nos plans avec un essai de 10 jours et annulez à tout moment.</p>
            </div>
            <div className='NavigationPricing'>
                <div className='containerButtonNavigationPricing'>
                    <button className={`ButtonMenuPricing ${activeButton ? "active" : ""}`} onClick={() => setActiveButton(true)}>
                        Personnel
                    </button>
                    <button className={`ButtonMenuPricing ${activeButton ? "" : "active"}`} onClick={() => setActiveButton(false)}>
                        Entreprise
                    </button>
                    <div className={`buttonActiveLinePricing ${activeButton ? "" : "active"}`}></div>
                </div>
            </div>
            <div className='NavigationPricing'>
                <div className='containerButtonNavigationPricing date'>
                    <button className={`buttonPricingDate ${pricingDate ? "active" : ""}`} onClick={() => setPricingDate(true)}>
                        Par mois
                    </button>
                    <button className={`buttonPricingDate ${pricingDate ? "" : "active"}`} onClick={() => setPricingDate(false)}>
                        Par an
                    </button>
                    <div className={`buttonActiveBlockPricing ${pricingDate ? "" : "active"}`}></div>
                </div>
            </div>
            
            {activeButton ? (
                <div className='containerPricingPack'>
                    <PricingPack title={"Platinium"} priceMounth={"19,99€"} priceYear={"200€"} popular={false} pricingDate={pricingDate} pack={["Toutes les fonctionnalités de la version Pro", "6 profils prêts à l'emploi", "Bénéficiez de l'aide de 4 collaborateurs", "Visualisez les analyses de durée de vie de tous vos profils", "Analysez le trafic de vos profils en profondeur grâce à nos outils de reporting et de filtrage", "Exportez vos données d'analyse au format .csv"]}  />
                    <PricingPack title={"Premium"} priceMounth={"16,99€"} priceYear={"150€"} popular={true} pricingDate={pricingDate} pack={["Toutes les fonctionnalités gratuites", "Types de liens puissants et polyvalents pour maintenir votre contenu à jour", "Gestion de plusieurs profils à partir d'un seul compte", "Analyses approfondies pour suivre les vues, les clics, les références, la géolocalisation, etc.", "Déverrouillez les intégrations premium telles que les formulaires d'opt-in, les formulaires, les pixels de suivi, etc.", "Ajoutez votre propre domaine à votre profil pour une URL personnalisée"]}  />
                    <PricingPack title={"Standard"} priceMounth={"9,99€"} priceYear={"100€"} popular={false} pricingDate={pricingDate} pack={["Lien bio ultra-rapide conçu pour les conversions", "Liens illimités", "Fonctions de personnalisation avancées pour refléter votre marque"]}  />
                </div>
            
            ):(null)}
            {!activeButton ? (
                <div className='containerPricingPack'>
                    <PricingPack title={"Platinium"} priceMounth={"19,99€"} priceYear={"200€"} popular={false} pricingDate={pricingDate} pack={["Toutes les fonctionnalités de la version Pro", "6 profils prêts à l'emploi", "Bénéficiez de l'aide de 4 collaborateurs", "Visualisez les analyses de durée de vie de tous vos profils", "Analysez le trafic de vos profils en profondeur grâce à nos outils de reporting et de filtrage", "Exportez vos données d'analyse au format .csv"]}  />
                    <PricingPack title={"Premium"} priceMounth={"16,99€"} priceYear={"150€"} popular={true} pricingDate={pricingDate} pack={["Toutes les fonctionnalités gratuites", "Types de liens puissants et polyvalents pour maintenir votre contenu à jour", "Gestion de plusieurs profils à partir d'un seul compte", "Analyses approfondies pour suivre les vues, les clics, les références, la géolocalisation, etc.", "Déverrouillez les intégrations premium telles que les formulaires d'opt-in, les formulaires, les pixels de suivi, etc.", "Ajoutez votre propre domaine à votre profil pour une URL personnalisée"]}  />
                    <PricingPack title={"Standard"} priceMounth={"9,99€"} priceYear={"100€"} popular={false} pricingDate={pricingDate} pack={["Lien bio ultra-rapide conçu pour les conversions", "Liens illimités", "Fonctions de personnalisation avancées pour refléter votre marque"]}  />
                </div>
            ):(null)}
            <FAQ />
            <GetStarted/>
            <Footer />
         </div>
    );
}

export default Pricing;