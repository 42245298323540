import React, { useState, useEffect } from 'react';
import { useUser } from '../../utils/contexts/userContext';
import './LinkMusic.css';
import { Image } from 'lucide-react';
import { getSpotifyTrackById } from '../../services/api/spotify';
import { getDeezerTrackById } from '../../services/api/deezer';
import { getAppleMusicTrackById } from '../../services/api/appleMusic';
import { getYoutubeTrackById } from '../../services/api/youtubeMusic';
import SpotifyLogo from '../../assets/spotify-color.png';
import DeezerLogo from '../../assets/deezer-color.png';
import AppleMusicLogo from '../../assets/applemusic-color.png';
import YoutubeMusicLogo from '../../assets/youtubemusic-color.png';

const LinkMusicEditComponent = ({ container }) => {
    const [musicData, setMusicData] = useState(null);
    const [loading, setLoading] = useState(true);
    const { user } = useUser();

    useEffect(() => {
        async function fetchMusicData() {
            if (container.idMedia && Object.keys(container.idMedia).length > 0) {
                try {
                    const platforms = Object.keys(container.idMedia);

                    // Récupère les données depuis la première plateforme disponible
                    for (const platform of platforms) {
                        const musicId = container.idMedia[platform];
                        const data = await fetchMusicFromPlatform(platform, musicId);
                        if (data) {
                            setMusicData(data);
                            break; // Stoppe la recherche dès qu'une musique est trouvée
                        }
                    }
                } catch (error) {
                    console.error("Erreur lors de la récupération des données musicales :", error);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        }

        fetchMusicData();
    }, [container.idMedia]);

    const fetchMusicFromPlatform = async (platform, id) => {
        if (platform === 'spotify') return await getSpotifyTrackById(id);
        if (platform === 'deezer') return await getDeezerTrackById(id);
        if (platform === 'applemusic') return await getAppleMusicTrackById(id);
        if (platform === 'youtubemusic') return await getYoutubeTrackById(id);
        return null;
    };

    const renderLogo = (platform) => {
        switch (platform) {
            case 'spotify':
                return SpotifyLogo;
            case 'deezer':
                return DeezerLogo;
            case 'applemusic':
                return AppleMusicLogo;
            case 'youtubemusic':
                return YoutubeMusicLogo;
            default:
                return null;
        }
    };

    return (
        <div className="flex items-center">
            {loading ? (
                <p>Chargement...</p>
            ) : (
                <>
                    {musicData?.image ? (
                        <img
                            src={musicData.image}
                            alt="Music Cover"
                            className="mx-2 w-16 h-16 object-cover object-center rounded-lg"
                        />
                    ) : (
                        <Image className="mx-2 w-16 h-16" />
                    )}
                    <div className="flex flex-col">
                        <strong className="titleOverflow mr-2 text-sm truncate">
                            {musicData?.title || "Titre de la musique"}
                        </strong>
                        <p className="titleOverflow mr-2 text-sm truncate">
                            {musicData?.artists?.join(", ") || "Nom du chanteur"}
                        </p>
                        <div className="flex gap-1 mt-1">
                            {Object.keys(container.idMedia).map((platform) => (
                                <img
                                    key={platform}
                                    src={renderLogo(platform)}
                                    alt={platform}
                                    title={platform}
                                    className="w-4 h-4"
                                />
                            ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default LinkMusicEditComponent;
