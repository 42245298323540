import React, { useState, useEffect } from 'react';
import './File.css';
import { useUser } from '../../utils/contexts/userContext';
import { useSelector } from 'react-redux';
import { getImageComponent } from '../../services/storage/storageService';
import { getFileIcon, getFileExtension, formatAncre } from '../../utils';
import { Download } from "lucide-react"
import { hexToRgb } from '../../utils';

const FileComponent = (props) => {
    const { user } = useUser();
    const [file, setFile] = useState(null);
    const [fileUrl, setFileUrl] = useState(null);
    const email = useSelector((state) => state.user.email);
    const color = useSelector((state) => state.user.colorFont);
    const bgdImage = useSelector(state => state.user.backgroundImage);

    useEffect(() => {
        const fetchMedia = async () => {
            if (props.id) {
                try {
                    // Récupération de l'URL du fichier depuis Firestore
                    const url = await getImageComponent(email, 'File', props.id);

                    // Téléchargement du fichier en tant que Blob
                    const response = await fetch(url);
                    const blob = await response.blob();

                    // Transformation du Blob en objet File et création de l'URL téléchargeable
                    const fileObject = new File([blob], `${formatAncre(props.title)}${getFileExtension(blob)}`, { type: blob.type });
                    setFile(fileObject);
                    setFileUrl(URL.createObjectURL(fileObject)); // Crée une URL temporaire pour le téléchargement

                } catch (error) {
                    console.error('Erreur lors de la récupération du média:', error);
                    setFile(null);
                }
            }
        };

        fetchMedia();

        // Nettoyage de l'URL temporaire lors du démontage du composant
        return () => {
            if (fileUrl) URL.revokeObjectURL(fileUrl);
        };
    }, [props.id, user.page]);

    return (
        <div className="File" style={{backgroundColor: bgdImage ? hexToRgb(user.backgroundColor) : 'rgba(255,255,255,0.15', color: color }}>
            {!file ? (
                <div className="containerImageDefault">
                    <i className="fa-solid fa-image" style={{color: color}}></i>
                </div>
            ) : (
                <img className="imgTicket" style={{ backgroundColor: color }} src={getFileIcon(file)} alt="icon produit" />
            )}

            <div className="infoTicket">
                <p style={{ color: color }} className="titleTicket line-clamp-2">{props.title}</p>
                {props.subtitle !== "" && <p style={{ color: color }} className="priceTicket line-clamp-2">{props.subtitle}</p>}
            </div>

            {fileUrl && (
                <a href={fileUrl} download={file.name} className="flex items-center w-6 h-6 justify-center mr-4 rounded-full p-1 cursor-pointer">
                    <Download color={color} size={20} />
                </a>
            )}
        </div>
    );
}

export default FileComponent;
