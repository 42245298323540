import React, { useState, useRef } from 'react';
import Modal from 'react-modal';
import styles from './QRCodeButton.module.css';
import { useHobspace } from '../../../utils/contexts/hobspaceContext';
import QRCodeStyling from 'qr-code-styling';
import '@fortawesome/fontawesome-free/css/all.css';
import { useSelector } from 'react-redux';
import { formatPhoneNumber } from '../../../utils';
import Logo from "../../../assets/logoIcon.png";

Modal.setAppElement('#root');

const QRCodeButton = (props) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const { hobspace } = useHobspace();
    const websiteUrl = window.location.href;
    const qrCodeRef = useRef(null); // Référence pour le QR code

    const openModal = () => {
        setModalIsOpen(true);
        generateQRCode();
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    // Initialiser les paramètres de style du QR code
    const qrCode = new QRCodeStyling({
        width: 250,
        height: 250,
        margin: 10,
        type: "svg",
        image: Logo, // Ajouter une image centrale si nécessaire
        dotsOptions: {
            color: "#000000",
            type: "rounded",
        },
        cornersSquareOptions: {
            color: "#ff8500",
            type: "extra-rounded",
        },
        cornersDotOptions: {
            color: "#ff8500",
            type: "dot",
        },
        backgroundOptions: {
            color: "#ffffff",
            opacity: 0.8,
        },
        imageOptions: {
            crossOrigin: "anonymous",
            margin: 0,
            imageSize: 1,
        },
        qrOptions: {
            typeNumber: 8,
            errorCorrectionLevel: 'L',
            mode: 'Byte'
        }
    });

    const generateQRCode = async() => {
        const contactData = await `
BEGIN:VCARD
VERSION:4.0
FN:${hobspace.firstname} ${hobspace.lastname}
ORG:${hobspace.organization || ""}
TEL;TYPE=WORK,VOICE:${formatPhoneNumber(`+${hobspace.phoneNumber}`, hobspace.country.code)}
EMAIL:${props.email}
URL:${websiteUrl}
END:VCARD
`;
        qrCode.update({
            data: contactData
        });
        qrCode.append(qrCodeRef.current);
    };

    // Fonction pour envoyer une requête POST au serveur Express et télécharger la vCard
    const addContact = async () => {
        try {
            const baseUrl = process.env.REACT_APP_BASE_URL || window.location.origin;
            const port = process.env.REACT_APP_PORT || 8000;
            const response = await fetch(`${baseUrl}:${port}/add-contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    firstName: hobspace.firstname || "",
                    lastName: hobspace.lastname || "",
                    organization: hobspace.organization || "",
                    workPhone: formatPhoneNumber(`+${hobspace.phoneNumber}`, hobspace.country.code),
                    email: props.email || "",
                    url: websiteUrl || "",
                }),
            });

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${hobspace.firstname}_${hobspace.lastname}.vcf`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } else {
                console.error('Erreur lors de la génération de la vCard');
            }
        } catch (error) {
            console.error('Erreur lors de la requête au serveur:', error);
        }
    };

    return (
        <>
            <button className={styles.qrcode} onClick={openModal}>
                <div className='iconButton'>
                    <i style={{ color: hobspace.colorFont }} className={props.img}></i>
                </div>
            </button>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="QR Code Modal"
                className={styles.qrCodeModal}
                overlayClassName={styles.qrCodeOverlay}
            > <div className={styles.sectionQRCode}>
                  <div className={styles.containerQRCode}>
                    <div className={styles.containerProfilAddContact} style={{backgroundColor: hobspace.backgroundColor}}>
                      <img className={styles.imgProfilAddContact} src={props.pp} alt='profile utilisateur' />
                      <div className={styles.profilAddContact}>
                          <p className='text-xl font-semibold' style={{color: hobspace.colorFont}} >{hobspace.lastname} {hobspace.firstname}</p>
                      </div>
                    </div>
                      <div className={styles.qrCodeAddContact} ref={qrCodeRef} />
                  </div>
                  <button onClick={addContact} className={styles.addContactButton}>
                      Ajouter le contact
                  </button>
            </div>
                
            </Modal>
        </>
    );
};

export default QRCodeButton;
