import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL || window.location.origin;

// Fonction générique pour appeler ton serveur Express
const fetchFromDeezer = async (endpoint) => {
    try {
        // Assurez-vous que `endpoint` ne commence pas par `/api/deezer`
        const url = `${baseUrl}${endpoint}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(`Erreur lors de l'appel à l'API Deezer (${endpoint}):`, error.message);
        throw new Error('Erreur lors de la récupération des données Deezer.');
    }
};

export const searchDeezerTrack = async (query, limit = 5) => {
    const endpoint = `/api/deezer/search?q=${encodeURIComponent(query)}&limit=${limit}`;

    // Appel à l'API via fetchFromDeezer
    const response = await fetchFromDeezer(endpoint);

    // Vérification de la structure des données avant de les mapper
    if (!response || !response.data || !Array.isArray(response.data)) {
        throw new Error('Les données renvoyées par l\'API Deezer sont invalides.');
    }

    // Transformation des résultats
    return response.data.map((track) => ({
        title: track.title || 'Titre inconnu',
        artists: [track.artist?.name || 'Artiste inconnu'],
        image: track.album?.cover_medium || '',
        link: track.link || '',
        id: track.id || null,
        platform: 'deezer',
    }));
};

export const getDeezerTrackById = async (id) => {
    if (!id) throw new Error("Le paramètre 'id' est requis.");

    const response = await fetchFromDeezer(`/api/deezer/track/${id}`);

    // Validation de la structure des données renvoyées
    if (!response || !response.id || !response.title || !response.artist || !response.album) {
        throw new Error('Les données renvoyées par l\'API Deezer pour cette musique sont invalides.');
    }

    // Transformation des données
    return {
        title: response.title,
        artists: [response.artist.name],
        image: response.album.cover_medium || '',
        link: response.link || '',
        preview: response.preview || null, // Lien de prévisualisation
        platform: 'deezer',
    };
};

// Fonction pour récupérer les informations d'un artiste
export const getDeezerArtistInfo = async (artistId) => {
    return fetchFromDeezer(`/artist/${artistId}`);
};

// Fonction pour récupérer les musiques d'un artiste (top 10)
export const getDeezerTopTracks = async (artistId, limit = 10) => {
    return fetchFromDeezer(`/artist/${artistId}/top?limit=${limit}`);
};

// Fonction pour récupérer les albums d'un artiste
export const getDeezerAlbums = async (artistId) => {
    return fetchFromDeezer(`/artist/album/${artistId}`);
};

// Fonction pour récupérer les musiques d'un album
export const getDeezerAlbumTracks = async (albumId) => {
    return fetchFromDeezer(`/album/${albumId}`);
};

// Fonction pour rechercher des podcasts
export const searchDeezerPodcasts = async (query) => {
    return fetchFromDeezer(`/search/${query}/?media=podcast`);
};

// Fonction pour récupérer les podcasts d'un créateur
export const getDeezerCreatorPodcasts = async (creatorName) => {
    return fetchFromDeezer(`/search?q=artist:"${creatorName}"&media=podcast`);
};

// Fonction pour récupérer les 10 derniers podcasts d'un créateur
export const getDeezerLatestPodcasts = async (creatorName) => {
    return fetchFromDeezer(`/search?q=artist:"${creatorName}"&media=podcast&order=date&limit=10`);
};

// Fonction pour récupérer les 10 podcasts les plus vus d'un créateur
export const getDeezerTopPodcasts = async (creatorName) => {
    return fetchFromDeezer(`/search?q=artist:"${creatorName}"&media=podcast&order=viewCount&limit=10`);
};
