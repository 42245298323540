import React, { useState, useEffect, useRef } from 'react';
import './LinkMusic.css';
import CustomModalPreview from '../Modal/CustomModal/CustomModalPreview';
import { useUser } from '../../utils/contexts/userContext';
import { useSelector } from 'react-redux';
import { hexToRgb } from '../../utils';
import { getSpotifyTrackById } from '../../services/api/spotify';
import { getDeezerTrackById } from '../../services/api/deezer';
import { getAppleMusicTrackById } from '../../services/api/appleMusic';
import { getYoutubeTrackById } from '../../services/api/youtubeMusic';
import AppleMusic from '../../assets/applemusic-color.png';
import Deezer from '../../assets/deezer-color.png';
import Spotify from '../../assets/spotify-color.png';
import YoutubeMusic from '../../assets/youtubemusic-color.png';

function LinkMusic({ idMedia }) {
    const [showModal, setShowModal] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [musicData, setMusicData] = useState(null); // Données principales
    const [platformData, setPlatformData] = useState([]); // Toutes les données des plateformes
    const bgdImage = useSelector((state) => state.user.backgroundImage);
    const color = useSelector((state) => state.user.colorFont);
    const { user } = useUser();
    const audioRef = useRef(null);

    useEffect(() => {
        if (idMedia && Object.keys(idMedia).length > 0) {
            fetchAllPlatformData();
        }
    }, [idMedia]);

    const renderLogo = (platform) => {
        switch (platform) {
            case 'spotify':
                return {logo: Spotify, color: "#1cd05d", title: "Spotify"};
            case 'deezer':
                return {logo: Deezer, color: "#9e34f7", title: "Deezer"};
            case 'applemusic':
                return {logo: AppleMusic, color: "#f64659", title: "Apple Music"};
            case 'youtubemusic':
                return {logo: YoutubeMusic, color: "#ff1512", title: "Youtube Music"};
            default:
                return null;
        }
    };

    const fetchMusicData = async (platform, id) => {
        try {
            switch (platform) {
                case 'spotify':
                    return await getSpotifyTrackById(id);
                case 'deezer':
                    return await getDeezerTrackById(id);
                case 'applemusic':
                    return await getAppleMusicTrackById(id);
                case 'youtubemusic':
                    return await getYoutubeTrackById(id);
                default:
                    return null;
            }
        } catch (error) {
            console.error(`Erreur lors de la récupération des données pour ${platform}:`, error);
            return null;
        }
    };

    const fetchAllPlatformData = async () => {
        const platforms = Object.entries(idMedia);
        const results = await Promise.all(
            platforms.map(async ([platform, id]) => {
                const data = await fetchMusicData(platform, id);
                return data ? { platform, ...data } : null;
            })
        );

        const validResults = results.filter(Boolean);
        setPlatformData(validResults);

        // Définit les données principales à partir de la première plateforme ajoutée
        if (validResults.length > 0) {
            setMusicData(validResults[0]);
        }
    };

    const playPreview = () => {
        if (musicData?.preview) {
            audioRef.current.src = musicData.preview;
            audioRef.current.play();
            setIsPlaying(true);
        } else if (musicData?.link) {
            window.open(musicData.link, '_blank', 'noopener,noreferrer');
        }
    };

    const stopPreview = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            setIsPlaying(false);
        }
    };

    const handlePreviewEnd = () => {
        setIsPlaying(false);
    };

    return (
        <a
            href={musicData?.link}
            target="_blank"
            className="LinkMusic"
            style={{
                backgroundColor: bgdImage
                    ? hexToRgb(user.backgroundColor)
                    : 'rgba(255,255,255,0.15)',
                color,
            }}
            rel="noopener noreferrer"
        >
            <div className="containerInfoMusic">
                <p className="title truncate" style={{ color: user.colorFont }}>
                    {musicData?.title || 'Titre de la musique'}
                </p>
                <p
                    className="singer truncate"
                    style={{ color: hexToRgb(user.colorFont, 0.6) }}
                >
                    {musicData?.artists?.join(', ') || 'Nom du chanteur'}
                </p>
                <div className="containerButtonLinkMusic">
                    {!isPlaying ? (
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                playPreview();
                            }}
                            className="play"
                            style={{
                                color: user.colorFont,
                                border: `1px solid ${user.colorFont}`,
                            }}
                        >
                            Play
                        </button>
                    ) : (
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                stopPreview();
                            }}
                            className="play"
                            style={{
                                color: user.colorFont,
                                border: `1px solid ${user.colorFont}`,
                            }}
                        >
                            Stop
                        </button>
                    )}
                    {platformData.length > 1 && (
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setShowModal(true);
                            }}
                            className="more"
                            style={{
                                backgroundColor: user.colorFont,
                                border: `1px solid ${user.colorFont}`,
                                color: user.backgroundColor,
                            }}
                        >
                            More
                        </button>
                    )}
                </div>
            </div>
            {musicData?.image ? (
                <img className="music" src={musicData.image} alt="music" />
            ) : (
                <div className="containerImageDefaultMusic">
                    <i className="fa-solid fa-image" style={{ color }}></i>
                </div>
            )}
            <audio ref={audioRef} onEnded={handlePreviewEnd} />
            <CustomModalPreview
                isOpen={showModal}
                onClose={() => setShowModal(false)}
            >
                <div className={`flex items-center flex-col justify-center relative w-full`}>
                    <div className='relative flex flex-row items-center justify-around w-4/5 h-20 gap-2 rounded-t-[10px]' style={{ backgroundColor: user.backgroundColor }}>
                        <img className={`rounded-[10px] object-cover object-center ml-3 mb-7 h-[80px] w-[80px] top-[-20px] left-[15px]`} src={musicData?.image || null} alt="music"/>
                        <div className={`text-left top-[10px] mr-3 w-full`}>
                            <p className={`text-[14px] capitalize truncate text-left font-semibold overflow-hidden w-full m-0`} style={{ color: user.colorFont }}>
                            {musicData?.title || 'Titre de la musique'}
                            </p>
                            <p className={`capitalize text-white text-opacity-60 font-medium text-[12px] mt-[2px] mb-[10px]`}>
                            {musicData?.artists?.length > 1
                                ? musicData.artists.join(', ')
                                : musicData?.artists?.[0] || 'Nom du chanteur'}
                            </p>
                        </div>
                    </div>
                    <div className={`flex flex-col w-4/5 items-center justify-center rounded-b-[10px] bottom-[0px] gap-0 px-3 py-2`} style={{ backgroundColor: user.colorFont }}>
                        {platformData.map(({ platform, link }, index) => (
                            <div className='w-full' key={platform}>
                                <a className={`flex items-center justify-between flex-row cursor-pointer no-underline`} href={link || '#'} target="_blank" rel="noreferrer">
                                    <div className={`flex items-center justify-start gap-2`}>
                                        <img className={`object-contain object-center h-[18px] w-auto`} src={renderLogo(platform).logo} alt={`${platform} logo`}/>
                                        <p className='text-xs font-bold text-left' style={{color: renderLogo(platform).color}}>{renderLogo(platform).title} </p>
                                    </div>
                                    
                                    <p className={`rounded-[20px] no-underline leading-[12px] cursor-pointer border border-black bg-transparent capitalize text-black font-medium text-[12px] px-[8px] py-[3px]`}>Play</p>
                                </a>
                                {index !== platformData.length - 1 &&
                                   <div className={`h-[0.5px] w-[90%] bg-black bg-opacity-10 rounded-[50px] my-[5px]`}></div> 
                                }
                                
                            </div>
                        ))}
                    </div>
                </div>
            </CustomModalPreview>
        </a>
    );
}

export default LinkMusic;
