import axios from 'axios';

const API_KEY = process.env.REACT_APP_API_KEY_YOUTUBE; // Ta clé API YouTube
const BASE_URL = 'https://www.googleapis.com/youtube/v3';

// Fonction pour effectuer des requêtes génériques à l'API YouTube
const fetchFromYoutube = async (endpoint, params) => {
    try {
        const response = await axios.get(`${BASE_URL}/${endpoint}`, {
            params: { key: API_KEY, ...params },
        });
        return response.data;
    } catch (error) {
        console.error(`Erreur lors de l'appel à l'API YouTube (${endpoint}):`, error);
        return null;
    }
};

export const searchYoutubeTrack = async (query, maxResults = 1) => {
    try {
        const response = await axios.get('https://www.googleapis.com/youtube/v3/search', {
            params: {
                part: 'snippet',
                q: query,
                type: 'video',
                maxResults,
                key: API_KEY, // Utilisez votre clé API
            },
        });

        if (response.data && response.data.items) {
            return response.data.items.map((item) => ({
                id: item.id.videoId,
                title: item.snippet.title,
                artists: [item.snippet.channelTitle], // Le titre du channel comme artiste
                image: item.snippet.thumbnails.default.url,
                link: `https://www.youtube.com/watch?v=${item.id.videoId}`,
                platform: 'youtubemusic',
            }));
        }

        return [];
    } catch (error) {
        console.error('Erreur lors de la recherche sur YouTube Music:', error);
        throw error;
    }
};

export const getYoutubeTrackById = async (id) => {
    const apiKey = API_KEY; // Remplace par ta clé API YouTube
    const response = await fetch(
        `https://www.googleapis.com/youtube/v3/videos?part=snippet,contentDetails&id=${id}&key=${apiKey}`
    );
    const data = await response.json();

    const video = data.items[0]?.snippet;

    return {
        title: video.title,
        artists: [video.channelTitle], // Utilise le nom de la chaîne comme artiste
        image: video.thumbnails.medium?.url || '',
        link: `https://www.youtube.com/watch?v=${id}`,
        preview: null, // Pas de flux audio direct disponible
        platform: 'youtubemusic',
    };
};

// Fonction pour récupérer les informations d'un artiste
export const getYoutubeArtistInfo = async (artistName) => {
    const data = await fetchFromYoutube('search', {
        part: 'snippet',
        q: artistName,
        type: 'channel',
        maxResults: 1,
    });
    return data?.items?.[0] || null;
};

// Fonction pour récupérer les vidéos d'un artiste (top 10)
export const getYoutubeArtistTopTracks = async (channelId, limit = 10) => {
    return fetchFromYoutube('search', {
        part: 'snippet',
        channelId: channelId,
        type: 'video',
        order: 'viewCount',
        maxResults: limit,
    });
};

// Fonction pour récupérer les albums d'un artiste
export const getYoutubeAlbums = async (artistName, limit = 10) => {
    return fetchFromYoutube('search', {
        part: 'snippet',
        q: `${artistName} album`,
        type: 'playlist',
        maxResults: limit,
    });
};

// Fonction pour récupérer les vidéos d'une playlist (musiques d'un album)
export const getYoutubeAlbumTracks = async (playlistId, limit = 10) => {
    return fetchFromYoutube('playlistItems', {
        part: 'snippet',
        playlistId: playlistId,
        maxResults: limit,
    });
};

// Fonction pour rechercher des podcasts
export const searchYoutubePodcasts = async (query, limit = 10) => {
    return fetchFromYoutube('search', {
        part: 'snippet',
        q: `${query} podcast`,
        type: 'video',
        maxResults: limit,
    });
};

// Fonction pour récupérer les vidéos d'un créateur
export const getYoutubeCreatorPodcasts = async (channelId, limit = 10) => {
    return fetchFromYoutube('search', {
        part: 'snippet',
        channelId: channelId,
        type: 'video',
        q: 'podcast',
        maxResults: limit,
    });
};

// Fonction pour récupérer les 10 derniers podcasts d'un créateur
export const getYoutubeLatestPodcasts = async (channelId, limit = 10) => {
    return fetchFromYoutube('search', {
        part: 'snippet',
        channelId: channelId,
        type: 'video',
        q: 'podcast',
        order: 'date',
        maxResults: limit,
    });
};

// Fonction pour récupérer les 10 podcasts les plus vus d'un créateur
export const getYoutubeTopPodcasts = async (channelId, limit = 10) => {
    return fetchFromYoutube('search', {
        part: 'snippet',
        channelId: channelId,
        type: 'video',
        q: 'podcast',
        order: 'viewCount',
        maxResults: limit,
    });
};
