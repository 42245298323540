import React, { useEffect } from 'react';

const DynamicManifest = ({ title }) => {
    useEffect(() => {
        const updateManifest = async () => {
            try {
                const path = window.location.pathname;
                const baseUrl = process.env.REACT_APP_BASE_URL || window.location.origin;

                // Appelle la route pour générer/modifier le manifest
                await fetch(`http://localhost:8000/manifest.json?path=${encodeURIComponent(path)}&title=${encodeURIComponent(title)}`);
            } catch (error) {
                console.error("Erreur lors de la mise à jour du manifest :", error);
            }
        };

        updateManifest();
    }, [title]); // Met à jour lorsque le titre change

    return null;
};

export default DynamicManifest;
