
import React, { useEffect, useState, useCallback } from 'react';
import styles from './OneVideo.module.css';
import { getYoutubeVideo, getYoutubeVideoId } from '../../../services/api/youtube';
import { useHobspace } from '../../../utils/contexts/hobspaceContext';

function OneVideo(props) {
    const [description, setDescription] = useState("");
    const [videoId, setVideoId] = useState("");
    const { hobspace } = useHobspace();

    // Fonction pour charger la vidéo
    const loadVideo = useCallback(async () => {
        try {
            const id = await getYoutubeVideoId(props.idMedia, hobspace); // Récupération de l'ID de la vidéo
            setVideoId(id);
            if (id) {
                const video = await getYoutubeVideo(id); // Récupération des informations de la vidéo
                if (video.snippet.description !== "") {
                    const descriptionLines = video.snippet.description.split('\n\n');
                    const selectedDescription = descriptionLines.slice(0, 2).join('\n\n');
                    setDescription(selectedDescription); // Mise à jour de la description
                }
            } else {
                console.error("Impossible de charger la vidéo. ID invalide.");
            }
        } catch (error) {
            console.error("Erreur lors du chargement de la vidéo:", error);
        }
    }, [props.idMedia]);

    useEffect(() => {
        loadVideo();
    }, [loadVideo]);

    return (
        <div className={styles.SectionOneVideo}>
            {/* <Title id={props.ancre} title={props.title} marginBottom={false} /> */}
            {props.description ? (
                <div className={styles.containerOneVideoDescript}>
                    <div className={styles.OneVideoDescript}>
                        <iframe 
                            className={styles.Oneminiature} 
                            src={`https://www.youtube.com/embed/${videoId}`} 
                            title="YouTube video player" 
                            frameBorder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                            allowFullScreen>
                        </iframe>
                    </div>
                    <p style={{ color: hobspace.colorFont }} className={styles.descriptionOneVideo}>
                        {description}
                    </p>
                </div>
            ) : (
                <div className={styles.OneVideo}>
                    <iframe 
                        className={styles.Oneminiature} 
                        src={`https://www.youtube.com/embed/${videoId}`} 
                        title="YouTube video player" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        allowFullScreen>
                    </iframe>
                </div>
            )}
        </div>
    );
}

export default OneVideo;
