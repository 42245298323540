import React, {useState, useEffect} from 'react';
import "./LinkConcert.css";
import InputFieldText from '../InputField/InputFieldText';
import { useUser } from '../../utils/contexts/userContext';
import { useDispatch } from 'react-redux';
import { setPage } from '../../services/store/reducers/userSlice';

const LinkConcertEdit = ({ obj, handleCloseModal }) => {
    
    const {user} = useUser();
    const [eventComponent, setEventComponent] = useState("");
    const [url, setUrl] = useState("");
    const [dayComponent, setDayComponent] = useState("");
    const [whereComponent, setWhereComponent] = useState("");
    const [mounthComponent, setMounthComponent] = useState("");
    const [yearComponent, setYearComponent] = useState("");
    const [boolComponent, setboolComponent] = useState(true);
    const dispatch = useDispatch();

    const handleChangeBool = () => {
        setboolComponent(!boolComponent);
    };

    const handleChangeLink = (newValue) => {
        setUrl(newValue);
    };

    const handleChangeEvent = (newValue) => {
        setEventComponent(newValue);
    };

    const handleChangeDay = (newValue) => {
        setDayComponent(newValue);
    };

    const handleChangeMounth = (newValue) => {
        setMounthComponent(newValue);
    };

    const handleChangeYear = (newValue) => {
        setYearComponent(newValue);
    };

    const handleChangeWhere = (newValue) => {
        setWhereComponent(newValue);
    };

    const sentEditComponent = () => {
        user.updateObjectInPageById(obj.id, {title: eventComponent , link: url, day: dayComponent, where: whereComponent, year: yearComponent, mounth: mounthComponent, type: boolComponent});
        dispatch(setPage(user.page));
        handleCloseModal();     
    }

    useEffect(() => {
        setEventComponent(obj.title); 
        setDayComponent(obj.day); 
        setWhereComponent(obj.where); 
        setYearComponent(obj.year); 
        setMounthComponent(obj.mounth); 
        setboolComponent(obj.type); 
        setUrl(obj.link);
    }, [user]);

    return (
        <>
        <div className='flex items-center flex-col justify-center w-full gap-6'>
            <InputFieldText value={eventComponent} maxLength={256} onChange={(e) => handleChangeEvent(e.target.value)} placeholder={""} title={"Nom de l'évènement"} type={true} />
            <InputFieldText value={whereComponent} maxLength={256} onChange={(e) => handleChangeWhere(e.target.value)} placeholder={""} title={"Lieu de l'évènement"} type={true} />
            <InputFieldText value={dayComponent} maxLength={2} onChange={(e) => handleChangeDay(e.target.value)} placeholder={""} title={"Jour de l'évènement"} type={true} />
            <InputFieldText value={mounthComponent} maxLength={20} onChange={(e) => handleChangeMounth(e.target.value)} placeholder={""} title={"Mois de l'évènement"} type={true} />
            <InputFieldText value={yearComponent} maxLength={4} onChange={(e) => handleChangeYear(e.target.value)} placeholder={""} title={"Année de l'évènement"} type={true} />
            <div className='containerDisplayEmail'>
                <p className='emailDisplayEmail' style={{textDecoration: boolComponent ? "" : "line-through"}}>Disponible</p>
                <label className="switch">
                    <input type="checkbox" checked={boolComponent} onChange={handleChangeBool} />
                    <span className="slider"></span>
                </label>
            </div>
            <InputFieldText value={url} maxLength={2048} onChange={(e) => handleChangeLink(e.target.value)} placeholder={""} title={"Lien"} type={true} />
        </div>
        <div className='containerButtonImgProfil'>
            <button className='buttonImgProfilClose' onClick={handleCloseModal}>Annuler</button>
            <button className='buttonImgProfilSave' onClick={sentEditComponent}>Enregistrer</button>
        </div>
        </>
    );
};

export default LinkConcertEdit;
