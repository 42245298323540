import axios from 'axios';

const APPLE_MUSIC_API_URL = 'https://api.music.apple.com/v1';
let developerToken = null; // Stockage en mémoire pour éviter les appels répétés

// Fonction pour récupérer le token Apple Music depuis le backend
const getAppleMusicToken = async () => {
    if (developerToken) {
        return developerToken; // Retourner le token s'il est déjà disponible
    }
    try {
        const response = await fetch('http://localhost:8000/generate-token'); 
        const data = await response.json();
        developerToken = data.token; // Stocker le token pour une réutilisation
        return developerToken;
    } catch (error) {
        console.error('Erreur lors de la récupération du token Apple Music:', error);
        return null; // Retourne null si le token ne peut pas être généré
    }
};

// Fonction générique pour appeler l'API Apple Music
const fetchFromAppleMusic = async (endpoint, userToken = null) => {
    try {
        console.log('fetchFromAppleMusic');
        const token = await getAppleMusicToken(); // Assure que le token est disponible
        console.log(token);
        if (!token) {
            throw new Error('Impossible de récupérer le token Apple Music.');
        }

        const headers = {
            Authorization: `Bearer ${token}`, // Inclure le token développeur
        };

        if (userToken) {
            headers['Music-User-Token'] = userToken; // Inclure le token utilisateur si nécessaire
        }

        const response = await axios.get(`${APPLE_MUSIC_API_URL}/${endpoint}`, { headers });
        return response.data;
    } catch (error) {
        console.error(`Erreur lors de l'appel à l'API Apple Music (${endpoint}):`, error);
        return null;
    }
};

// Fonctions pour les fonctionnalités spécifiques

export const searchAppleMusicTrack = async (query, limit = 5) => {
    const token = await getAppleMusicToken();
    console.log(token);
    const response = await fetch(
        `https://api.music.apple.com/v1/catalog/us/search?term=${encodeURIComponent(query)}&types=songs&limit=${limit}`,
        {
            headers: { Authorization: `Bearer ${token}` },
        }
    );
    const data = await response.json();
    console.log(data);
    // Normalisation des résultats
    return data.results.songs.data.map(song => ({
        title: song.attributes.name,
        artists: [song.attributes.artistName],
        image: song.attributes.artwork?.url.replace('{w}x{h}', '200x200') || '', // URL adaptée
        link: song.attributes.url,
        id: song.id,
        platform: 'applemusic',
    }));
};

export const getAppleMusicTrackById = async (id) => {
    const token = await getAppleMusicToken(); // Fonction pour obtenir le token Apple Music
    const response = await fetch(
        `https://api.music.apple.com/v1/catalog/us/songs/${id}`,
        {
            headers: { Authorization: `Bearer ${token}` },
        }
    );
    const data = await response.json();

    const song = data.data[0]?.attributes;

    return {
        title: song.name,
        artists: [song.artistName],
        image: song.artwork?.url.replace('{w}x{h}', '200x200') || '',
        link: song.url,
        preview: null, // Pas de prévisualisation disponible
        platform: 'applemusic',
    };
};

export const getAppleMusicArtistInfo = async (artistId) => {
    return fetchFromAppleMusic(`catalog/us/artists/${artistId}`);
};

export const getAppleMusicTopTracks = async (artistId, limit = 10) => {
    return fetchFromAppleMusic(`catalog/us/artists/${artistId}/top-songs?limit=${limit}`);
};

export const getAppleMusicAlbums = async (artistId, limit = 10) => {
    return fetchFromAppleMusic(`catalog/us/artists/${artistId}/albums?limit=${limit}`);
};

export const searchAppleMusicPodcasts = async (query, limit = 10) => {
    return fetchFromAppleMusic(`catalog/us/search?term=${encodeURIComponent(query)}&types=podcasts&limit=${limit}`);
};

export const getAppleMusicCreatorPodcasts = async (creatorId) => {
    return fetchFromAppleMusic(`catalog/us/artists/${creatorId}/podcasts`);
};

export const getAppleMusicLatestPodcasts = async (creatorId, limit = 10) => {
    return fetchFromAppleMusic(`catalog/us/artists/${creatorId}/podcasts?sort=releaseDate&limit=${limit}`);
};

export const getAppleMusicTopPodcasts = async (creatorId, limit = 10) => {
    return fetchFromAppleMusic(`catalog/us/artists/${creatorId}/podcasts?sort=playCount&limit=${limit}`);
};
