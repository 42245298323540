import { createSlice } from "@reduxjs/toolkit";
/*
- links lein modifier pas édit directement
- à chaque modifiecation de lien ou composant je vais devoir mettre à jour le state de links et page avec 
toutes les fonctions qui modifie un peu un composant

*/
const initialState = {
    backgroundImage: undefined,
    bannerImage: undefined,
    image: undefined,
}

export const hobspaceSlice = createSlice({
    name: "hobspace",
    initialState,
    reducers: {
        setImage(state, action) {
            state.image = action.payload; // Mettre à jour l'état avec les URLs des images
        }, 
        setBackgroundImage(state, action) {
            state.backgroundImage = action.payload; // Mettre à jour l'état avec les URLs des images
        },
        setBannerImage(state, action) {
            state.bannerImage = action.payload; // Mettre à jour l'état avec les URLs des images
        },
    }
})

export const { 
    setImage, 
    setBackgroundImage,
    setBannerImage,
} = hobspaceSlice.actions;
