import React, { useState } from 'react';
import './Contact.css';
import NavigationHome from '../../Layouts/Navigation/NavigationHome/NavigationHome';
import Footer from '../../Layouts/Footer/Footer';
import { Link } from "react-router-dom";
import TitleTabNavigator from '../../components/TitleTabNavigator/TitleTabNavigator';
import MetaTags from '../../components/MetaTags/MetaTags';

const Contact = () => {

    const bgdContainer = "#ffffff";
    const colorFont = "255, 255, 255";
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [reason, setReason] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Ici, vous pouvez ajouter la logique d'envoi du formulaire
        console.log('Formulaire envoyé avec succès :', { name, email, message, reason });
    };

    return (
        <div className="Contact">
            <MetaTags/>
            <TitleTabNavigator title={"Contact"}/>
            <NavigationHome color={colorFont} bgd={bgdContainer}/>
            <div className="contact-container">
                <h1>Contactez-nous</h1>
                <p>Avez-vous consulté notre page de <Link to={"/support"} className={"contactLinkSupport"}>support</Link> avant de nous contacter ?</p>
                <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Raison de votre message</label>
                    <select className="form-control" value={reason} onChange={(e) => setReason(e.target.value)}>
                        <option value="">Support</option>
                        <option value="support">Support technique</option>
                        <option value="question">Question générale</option>
                        <option value="feedback">Feedback</option>
                    </select>
                </div>
                <div className="form-group">
                    <label>Votre Nom</label>
                    <input className="form-control" value={name} placeholder='Alex DORÉ' onChange={(e) => setName(e.target.value)} />
                </div>
                <div className="form-group">
                    <label>Votre Email</label>
                    <input type="email" className="form-control" value={email} placeholder='alexdore@hobsspace.com' onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="form-group">
                    <label>Votre Message</label>
                    <textarea className="form-control area" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                </div>
                <button type="submit" className="btn-submit">Envoyer</button>
            </form>
            </div>
            <Footer/>
        </div>
    );
};

export default Contact;
