import React, {useState, useEffect, useRef} from 'react'
import styles from './Home.module.css';
//----- Components Demo -------------------------------------------
import LinkConcert from '../components/LinkConcert/LinkConcert';
import Event from '../components/Event/Event';
import Link from '../components/Link/Link';
import Links from "../components/Links/Links";
import OneVideo from '../components/OneVideo/OneVideo';
import IndividualVideo from '../components/IndividualVideo/IndividualVideo';
import ReseauxSociaux from "../components/ReseauxSociaux/ReseauxSociaux";
import Logo from "../components/Logo/Logo";
import Menu from "../components/Menu/Menu";
import EmailButton from '../components/EmailButton/EmailButton';
import CallButton from '../components/CallButton/CallButton';
import VideoCollection from '../components/VideoCollection/VideoCollection';
import LinkedinAccount from '../components/LinkedinAccount/LinkedinAccount';
import Gallery from '../components/Gallery/Gallery';
import Text from "../components/Text/Text";
import Media from '../components/Media/Media';
import Files from '../components/Files/Files';
import Merch from '../components/Merch/Merch';
import File from '../components/File/File';
import LinksMusic from '../components/LinksMusic/LinksMusic';
import LinkMusic from '../components/LinkMusic/LinkMusic';
//----------------------------------------------------------------
import { useSelector, useDispatch } from 'react-redux';
import { useHobspace } from '../../utils/contexts/hobspaceContext';
import { capitalizeFirstLetter, changeBodyBackgroundColor, formatAncre, hexToRgb } from '../../utils';
import Loader from '../components/Loader/Loader';
import { getImages } from '../../services/storage/storageService';
import { getHobspaceInfo, getEmailUser } from '../../services/storage/storageHobspaceService';
import TitleTabNavigator from '../components/TitleTabNavigator/TitleTabNavigator';
import MetaTags from '../../components/MetaTags/MetaTags';
import { setImage, setBackgroundImage, setBannerImage } from '../../services/store/reducers/hobspaceSlice';
import Banner from '../components/Banner/Banner';
import DynamicManifest from '../../DynamicManifest';
    
function Home({ hobspaceId }) { // Reçois l'ID en tant que prop
    const [email, setEmail] = useState(undefined);
    const [image, setImg] = useState(null);
    const [backgroundImage, setBackgroundImg] = useState(null);
    const [bannerImage, setBannerImg] = useState(null);
    const [bgdColor, setBgdColor] = useState("");
    const [fontColor, setFontColor] = useState("");
    const [displayEmail, setDisplayEmail] = useState(false);
    const [displayPhone, setDisplayPhone] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [component, setComponent] = useState([]);
    const [menu, setMenu] = useState([]);
    const { hobspace, loading, setHobspace } = useHobspace();
    const dispatch = useDispatch();
    const parentRef = useRef(null);
    const childRef = useRef(null);
    const [manifestTitle, setManifestTitle] = useState(`Hob's Space`); // État pour stocker le titre du manifest
  
    useEffect(() => {
      const handleScroll = () => {
        const parent = parentRef.current;
        const child = childRef.current;
  
        if (!parent || !child) return;
  
        const parentRect = parent.getBoundingClientRect();
        const childHeight = child.offsetHeight;
  
        if (parentRect.top >= 0) {
          // Positionner l'enfant en haut du conteneur parent
          child.style.top = "0px";
        } else if (parentRect.top < 0 && parentRect.bottom > childHeight) {
          // Garder l'enfant sticky dans les limites du conteneur parent
          child.style.top = `${-parentRect.top}px`;
        } else if (parentRect.bottom <= childHeight) {
          // Fixer l'enfant en bas du conteneur parent
          child.style.top = `${parentRect.height - childHeight}px`;
        }
      };
  
      window.addEventListener("scroll", handleScroll);
  
      return () => {
        window.removeEventListener("scroll", handleScroll); // Nettoyage à la fin
      };
    }, []);

    const renderComponent = (page) => {
        switch (page.name) {
            case "Link":
                return <Link id={page.id} title={page.title} subtitle={page.subtitle} link={page.link} visibility={page.visibility} />;
            case "Links":
                return <Links id={page.id} ancre={formatAncre(page.title)} title={page.title} components={page.components} visibility={page.visibility} email={email} />;
            case "LinksMusic":
                console.log(page);
                return <LinksMusic id={page.id} ancre={formatAncre(page.title)} title={page.title} components={page.components} visibility={page.visibility}/>;
            case "LinkMusic":
                console.log(page);
                return <LinkMusic id={page.id} idMedia={page.idMedia} visibility={page.visibility}/>;  
            case "Merch":
                return <Merch id={page.id} ancre={formatAncre(page.title)} title={page.title} components={page.components} visibility={page.visibility} email={email} />;
            case "LinkConcert":
                return <LinkConcert id={page.id} title={page.title} day={page.day} mounth={page.mounth} year={page.year} where={page.where} visibility={page.visibility}/>;
            case "Event":
                return <Event id={page.id} ancre={formatAncre(page.title)} title={page.title} components={page.components} visibility={page.visibility}/>;
            case "Video":
                return <OneVideo id={page.id} idMedia={page.idMedia} description={page.description} visibility={page.visibility}/>;    
            case "IndividualVideo":
                return <IndividualVideo id={page.id} title={page.title} components={page.components} visibility={page.visibility}/>;
            case "VideoCollection":
                return <VideoCollection id={page.id} ancre={formatAncre(page.title)} title={page.title} nbVideo={page.nbVideo} visibility={page.visibility}/>;
            case "LinkedinAccount":
                return <LinkedinAccount id={page.id} ancre={formatAncre(page.title)} title={page.title} description={page.description} visibility={page.visibility} />;
            case "Text":
                return <Text id={page.id} ancre={formatAncre(page.title)} title={page.title} description={page.description} visibility={page.visibility}/>;
            case "Gallery":
                return <Gallery id={page.id} ancre={formatAncre(page.title)} title={page.title} components={page.components} visibility={page.visibility} email={email} />;
            case "Media":
                return <Media id={page.id} ancre={formatAncre(page.title)} description={page.description} link={page.link} type={page.type} visibility={page.visibility}/>;
            case "File":
                return <File id={page.id} title={page.title} subtitle={page.subtitle} visibility={page.visibility} />;
            case "Files":
                return <Files id={page.id} ancre={formatAncre(page.title)} title={page.title} components={page.components} visibility={page.visibility} email={email} />;      
            default:
                return null;
        }
    };

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                setHobspace(null);
    
                // Récupérer l'email de l'utilisateur
                const e = await getEmailUser(hobspaceId);
                setEmail(e);
    
                // Récupérer les informations utilisateur (Hobspace)
                await getHobspaceInfo(hobspaceId, setHobspace);
    
                // Charger les images après avoir récupéré l'email
                try {
                    const img = await getImages(e, "profile");
                    if (img && img.length > 0) {
                        setImg(img[0]);
                        dispatch(setImage(img));
                    } else {
                        console.warn("No profile image found.");
                    }
                } catch (error) {
                    console.warn("Error fetching profile image:", error.message);
                }
    
                try {
                    const bgdImg = await getImages(e, "backgroundImage");
                    if (bgdImg && bgdImg.length > 0) {
                        setBackgroundImg(bgdImg[0]);
                        dispatch(setBackgroundImage(bgdImg));
                    } else {
                        console.warn("No background image found.");
                    }
                } catch (error) {
                    console.warn("Error fetching background image:", error.message);
                }

                try {
                    const bannerImg = await getImages(e, "banner");
                    if (bannerImg && bannerImg.length > 0) {
                        setBannerImg(bannerImg[0]);
                        dispatch(setBannerImage(bannerImg));
                    } else {
                        console.warn("No banner image found.");
                    }
                } catch (error) {
                    console.warn("Error fetching background image:", error.message);
                }
            } catch (error) {
                console.error("Error fetching user info or images:", error.message);
            }
        };
    
        fetchUserInfo();
    }, [hobspaceId, setHobspace]);      
    
    useEffect(() => {
        const updateTitle = () => {
            const currentTitle = document.title; 
            setManifestTitle(currentTitle);
        };

        // Observer les changements de titre
        const observer = new MutationObserver(updateTitle);
        const titleElement = document.querySelector('title');
        if (titleElement) {
            observer.observe(titleElement, { childList: true });
        }

        // Nettoyage
        return () => {
            if (observer) observer.disconnect();
        };
    }, []);

    // Utilise un autre useEffect qui s'exécute seulement lorsque user est mis à jour
    useEffect(() => {
        if (hobspace) {
            setBgdColor(hobspace.backgroundColor);
            changeBodyBackgroundColor(hobspace.backgroundColor);
            setFontColor(hobspace.colorFont);
            setDisplayEmail(hobspace.contactActive.email);
            setDisplayPhone(hobspace.contactActive.phone);
            setPhoneNumber(hobspace.phoneNumber);
    
            const pageActive = hobspace.page.map(page => ({
                id: page.id,
                name: page.name,
                component: renderComponent(page),
                icon: page.icon,
                color: page.color,
            }));
            setComponent(pageActive);
            setMenu(hobspace.page.map(component => component.title));
        }
    }, [hobspace]);

    return (
        <div className={styles.Home} style={{ backgroundColor: bgdColor }}>
            <MetaTags />
            {backgroundImage &&
                <img src={backgroundImage} className={styles.backgroundImage} />
            }
            <DynamicManifest title={manifestTitle} />
            <TitleTabNavigator title={capitalizeFirstLetter(hobspaceId)}/>
            {!loading && hobspace ? (
            <div ref={parentRef} className={`${styles.containerHome} relative`}>
            {backgroundImage && (
                <img
                ref={childRef}
                src={backgroundImage}
                alt="Background"
                className={`${styles.HomeImage} absolute top-0 left-0`}
                />
            )}
            <div className={styles.containerHomeContent} 
            style={{
                backgroundColor: backgroundImage ? "rgba(0,0,0,0.2)" : bgdColor,
                color: fontColor,
                boxShadow: backgroundImage
                  ? null
                  : `0px 0px 20px 3px ${hexToRgb(fontColor, 0.5)}`,
            }}>
                {/* Contenu principal */}
                {hobspace && (
                  <>
                  {/* <Banner image={bannerImage} /> */}
                    <Logo hobspace={hobspace} componentId={"Profile"} image={image} />
                    {displayEmail || displayPhone && (
                      <div className="flex flex-col items-center justify-center mb-6 gap-1">
                        {displayEmail && <EmailButton email={email} />}
                        {displayPhone && <CallButton phoneNumber={phoneNumber} />}
                      </div>
                    )}
                    <ReseauxSociaux
                      socialNetworks={hobspace.links}
                      email={email}
                      image={image}
                    />
                    <Menu menu={menu} />
                    <div className={styles.lineSeparation}></div>
                    {component &&
                      component.map((c, index) => (
                        <React.Fragment key={index}>
                          {c.component}
                          {index < component.length - 1 && (
                            <div className={styles.lineSeparation}></div>
                          )}
                        </React.Fragment>
                      ))}
                  </>
                )}
              </div>
            </div>
            ) : (<div className={styles.containerSpinner}>
                    <Loader color={fontColor} />
                </div>
                
            )}
        </div>
    );
}
    
export default Home;