import React, {useState, useEffect} from 'react'
import './Link.css';
import { useUser } from '../../utils/contexts/userContext';
import { useSelector } from 'react-redux';
import { hexToRgb } from '../../utils';
import { getImageComponent } from '../../services/storage/storageService';

function Link(props) {
    const [image, setImage] = useState(null);
    const bgdImage = useSelector(state => state.user.backgroundImage);
    const {user} = useUser();
    const email = useSelector((state) => state.user.email);
    const color = useSelector((state) => state.user.colorFont);

    useEffect(() => {
        const fetchMedia = async () => {
            if (props.id) {
                try {
                    // Utilisation de la fonction pour récupérer le média
                    const url = await getImageComponent(email, 'Link', props.id);
                    setImage(url); // Mettre à jour la prévisualisation avec l'URL récupérée
                } catch (error) {
                    console.error('Erreur lors de la récupération du média:', error);
                    setImage(null); // Réinitialiser si une erreur se produit
                }
            }
        };

        fetchMedia();
    }, [props.id, user.page]); 
 
    return (
        <a className={`Link`} href={props.link} style={{backgroundColor: bgdImage ? hexToRgb(user.backgroundColor) : "rgba(255,255,255,0.15)", color: color}}>
            {image === null ?
                <div className='containerImageDefault'>
                    <i className="fa-solid fa-image" style={{color: color}}></i>
                </div>
                :
                <img className={`imgTicket`} src={image} alt="icon produit" />
            }
            
            <div className={`infoTicket`}>
                <p style={{color: color}} className={`titleTicket line-clamp-2`}>{props.title}</p>
                {props.subtitle !== "" ? <p style={{color: color}} className={`priceTicket line-clamp-2`}>{props.subtitle}</p> : null}
            </div>
        </a>

    );
}

export default Link;