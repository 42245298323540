import React from 'react';
import { Helmet } from 'react-helmet';

function Layout({title}) {
    return (
        <Helmet>
            <title>Hob's Space - {title} </title>
        </Helmet>
        
    );
}

export default Layout;
