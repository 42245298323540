import React from 'react'
import styles from './LinkedinProfil.module.css';
import { useHobspace } from '../../../utils/contexts/hobspaceContext';
import Linkedin from "../../../assets/linkedin.png";
import { useSelector } from 'react-redux';
import { hexToRgb } from '../../../utils';

function LinkedinProfil(props) {

    const bgdImage = useSelector(state => state.hobspace.backgroundImage);
    const {hobspace} = useHobspace();
    const image = useSelector((state) => state.hobspace.image);
    // quand l'utilisateur aura connecté son compte faudra mettre le lien
    return (
        <div className={styles.LinkedinProfil} style={bgdImage ? {backgroundColor: hexToRgb(hobspace.backgroundColor, 1)} : {backgroundColor: `rgba(255,255,255,0.15)`}}>
            <img className={styles.imgProfil} src={image} alt='profile linkedin' />
            <div className={styles.containerInfoPerso}>
                <p className={`${styles.titleProfil} text-xl max-md:text-lg max-425:text-base max-350:text-sm`} style={{color: hobspace.colorFont}}>{hobspace.lastname + " " + hobspace.firstname}</p>
                <p className={`${styles.singerProfil} text-lg max-md:text-base max-425:text-sm max-350:text-xs`} style={{color: hobspace.colorFont}}>{props.description}</p>
            </div>
            <div className={styles.linkPlateform} style={bgdImage ? {backgroundColor: hobspace.colorFont} : {backgroundColor: hobspace.backgroundColor}}>
                {/* <a className={`linkImgPlateform`} href={""} target="_blank" rel="noreferrer">
                    <div className='iconButton'>
                        <i style={{color: user.colorFont, fontSize: "20px"}} className={"fa-solid fa-plus"}></i>
                    </div>
                    <p className="textLinkedinProfil" style={{color: user.colorFont}}>Suivre</p>
                </a>
                <div className={`lineProfil`}></div> */}
                <a className={styles.linkImgPlateform} href={""} target='_blank' rel="noreferrer">
                {/* <div className='iconButton'>
                    <i style={{color: user.colorFont, fontSize: "20px", top: "3px", position: "relative"}} className={"fa-regular fa-message"}></i>
                </div> */}
                    <div className={styles.linkImg}>
                        <i style={bgdImage ? {color: hobspace.backgroundColor} : {color: hobspace.colorFont}} className={"fa-brands fa-linkedin"}></i>
                    </div>
                    <p className={`${styles.textLinkedinProfil} text-lg max-md:text-base max-425:text-sm max-350:text-xs`} style={bgdImage ? {color: hobspace.backgroundColor} : {color: hobspace.colorFont}}>Découvrez mon Linkedin</p>
                </a>
            </div>
        </div>

    );
}

export default LinkedinProfil;