import React, { useState, useEffect } from 'react'
import styles from './LinksMusic.module.css';
import Title from '../Title/Title';
import Slider from '../Slider/Slider';
import { useHobspace } from '../../../utils/contexts/hobspaceContext';
import LinkMusic from '../LinkMusic/LinkMusic';

function LinksMusic(props) {
    const {hobspace} = useHobspace();
    const [components, setComponents] = useState([]);

        console.log(props.components);
    useEffect(() => {
        const components = props.components.map((c, index) => (
            <LinkMusic key={index} id={c.id} idMedia={c.idMedia} />
        ));
        console.log(props.components, components);
        setComponents(components);
    }, [props.components]); 
 
    return (
        <div className={styles.LinksMusic}>
            {props.title &&
                <Title id={props.ancre} color={hobspace.colorFont} title={props.title} marginBottom={true} />
            }

            
            {components.length > 0 && components.length < 4 && components.map((component, index) => (
                <React.Fragment key={index}>
                    <div className='flex flex-col items-center justify-center gap-3 w-full mt-3'>
                        {component}
                    </div>
                </React.Fragment>
            ))}

            {components.length >= 4 && 
                <Slider components={components} column={true} />   
            }
        </div>
    );
}

export default LinksMusic;