import React, { useState, useEffect } from 'react';
import { useUser } from '../../utils/contexts/userContext'; // Correction du chemin
import "./Merch.css";
import InputFieldText from '../InputField/InputFieldText';
import DragAndDropContainer from '../DragAndDropContainer/DragAndDropContainer';
import { componentsSpace, generateUniqueId } from '../../utils';
import { Trash2, PencilLine } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { setPage, setMenu } from '../../services/store/reducers/userSlice';

const MerchEdit = ({ renderComponentContent, linkContainer, deleteBlock }) => { 
    const { user } = useUser();
    const [visibility, setVisibility] = useState(true);
    const [edit, setEdit] = useState(false);
    const [title, setTitle] = useState(null);
    const dispatch = useDispatch();

    const handleChangeTitle = (newValue) => {
        setTitle(newValue);
    };

    const updateTitleComponent = (id) => { // Renommé fonction pour plus de clarté
        if (title !== null) {
            user.updateObjectInPageById(id, { title: title });
            dispatch(setPage(user.page));
            const anchors = user.page.map(component => component.title);
            dispatch(setMenu(anchors));
        }
        setEdit(false);
    };

    const removeComponents = (id) => {
        user.removeObjectById(id);
        dispatch(setPage(user.page));
    };

    const reorderListById = (list, newOrder) => {
        const orderMap = new Map(newOrder.map((item, index) => [item.id, index]));
        return list.slice().sort((a, b) => orderMap.get(a.id) - orderMap.get(b.id));
    };

    const handleReorderComponents = (id, newOrder) => {
        const pageComponent = user.page.find((component) => component.id === id);
        if (!pageComponent || !pageComponent.components) {
            console.error("L'élément avec cet ID n'existe pas ou n'a pas de composants.");
            return;
        }
        const newOrderComponents = reorderListById(pageComponent.components, newOrder);
        user.reorderObjectToComponents(id, newOrderComponents);
        dispatch(setPage(user.page));
    };

    const updateComponentsUser = (componentToAdd, id) => {
        const newActiveComponents = componentsSpace.find(page => page.name === componentToAdd);
        if (newActiveComponents) {
            const updatedComponent = { ...newActiveComponents, id: generateUniqueId() };
            user.addObjectToComponents(id, updatedComponent);
            dispatch(setPage(user.page));
        }
    };

    useEffect(() => {
        if(linkContainer) {
            setTitle(linkContainer.title);
        }
    }, [linkContainer]);

    return (
        <div className='flex h-auto mt-2 items-center'>
            {linkContainer &&
            <div className='flex flex-col w-full'>
                <div className='flex flex-row w-full justify-between gap-6'>
                    {edit ? (
                        <InputFieldText 
                            value={title} 
                            maxLength={256}
                            onChange={(e) => handleChangeTitle(e.target.value)} 
                            placeholder={title} 
                            title={""} 
                            type={true}
                            onBlur={() => updateTitleComponent(linkContainer.id)} // Appel de la nouvelle fonction renommée
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    updateTitleComponent(linkContainer.id);
                                }
                            }}
                        />
                    ) : (
                        <p className="mr-2 text-lg capitalize">{title}</p>
                    )}

                    <div className='flex flex-row gap-2'>
                        <button className='buttonDragAndDrop' onClick={() => setVisibility(!visibility)}>
                            {visibility ? (
                                <i className="fa-solid fa-eye" style={{ color: "#000" }}></i>
                            ) : (
                                <i className="fa-solid fa-eye-slash" style={{ color: "#000" }}></i>
                            )}
                        </button>
                        <button className='buttonDragAndDrop' onClick={() => setEdit(!edit)}>
                        <PencilLine />
                        </button>
                        <button className='buttonDragAndDrop' onClick={() => deleteBlock(linkContainer.id)}>
                        <Trash2 />
                        </button>
                    </div>
                </div>

                <div className='textDragAndDrop flex items-center justify-between mt-2'>
                    <div className="flex items-start flex-col w-full">
                        <button
                            onClick={() => updateComponentsUser("Link", linkContainer.id)}
                            className="flex p-2 text-xs text-white items-center flex-start bg-neutral-100 rounded-full"
                            style={{ backgroundColor: linkContainer.color }}
                        >
                            Ajouter du contenu
                        </button>

                        <div className="flex flex-col mt-3 w-full max-h-[250px] overflow-y-auto p-4 border border-gray-300 rounded-lg">
                            {linkContainer.components && linkContainer.components.length > 0 ? (
                                <DragAndDropContainer
                                    droppableId={linkContainer.id}
                                    initialContainers={linkContainer.components.map((component, index) => ({
                                        ...component,
                                        renderContent: () => (
                                            <React.Fragment key={index}>
                                                {renderComponentContent("Link", component)}
                                            </React.Fragment>
                                        )
                                    }))}
                                    deleteBlock={(id) => removeComponents(id)}
                                    onReorder={(reorderedComponents) =>
                                        handleReorderComponents(linkContainer.id, reorderedComponents)
                                    }
                                    editComponent={true}
                                />
                            ) : (
                                <p></p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    );
};

export default MerchEdit;
