import React, { useState, useEffect } from 'react'
import './Merch.css';
import Title from '../Title/Title';
import Slider from '../Slider/Slider';
import Article from '../Article/Article';
import Link from '../Link/Link';
import { useUser } from '../../utils/contexts/userContext';

function Merch(props) {
    const {user} = useUser();
    const [components, setComponents] = useState([]);
    const [componentsArticle, setComponentsArticle] = useState([]);

    useEffect(() => {
        const componentArticles = props.components.slice(3).map((c, index) => (
            <Article key={index} id={c.id} title={c.title} subtitle={c.subtitle} link={c.link} />
        ));
        const components = props.components.slice(0, 3).map((c, index) => (
            <Link key={index} id={c.id} title={c.title} subtitle={c.subtitle} link={c.link} />
        ));
        setComponents(components);
        setComponentsArticle(componentArticles);
    }, [props.components]); 
 
    return (
        <div className='Merch'>s
            {props.title &&
                <Title id={props.ancre} color={user.colorFont} title={props.title} marginBottom={true} />
            }
            
            {components.length > 0 && components.map((component, index) => (
                <React.Fragment key={index}>
                    <div className='flex flex-col items-center justify-center gap-3 w-full mt-3'>
                        {component}
                    </div>
                </React.Fragment>
            ))}
            
            {componentsArticle.length > 0 && 
                <Slider components={componentsArticle} />   
            }
        </div>
    );
}

export default Merch;