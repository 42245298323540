import { db, storage } from '../firebase/firebase.config';
import { doc, setDoc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import User from "./user";
import { ref, listAll, uploadBytes, getDownloadURL, deleteObject, getMetadata } from 'firebase/storage';
import { setNotify } from '../store/reducers/notifySlice';

const cleanData = (obj) => {
    if (Array.isArray(obj)) {
        return obj
            .map(cleanData) // Nettoie chaque élément
            .filter((item) => item !== undefined && item !== null); // Retire les éléments invalides
    } else if (obj && typeof obj === 'object') {
        return Object.keys(obj).reduce((acc, key) => {
            const value = obj[key];
            if (value !== undefined && value !== null) {
                acc[key] = cleanData(value); // Applique le nettoyage récursivement
            }
            return acc;
        }, {});
    }
    return obj; // Retourne directement la valeur si ce n'est pas un tableau ou un objet
};

export const createUser = async (user, userInfo, image) => {
    try {
      if (!user.email) {
        throw new Error('Email utilisateur manquant ou invalide.');
      }
      // Crée un nouvel objet User avec les informations
      const newUser = new User(userInfo);
      console.log(userInfo, newUser);
      // Ajoute les informations utilisateur dans Firestore
      await setDoc(doc(db, 'users', user.email), userInfo);
      await initializeUserStorage(user.email, image);
      console.log('Utilisateur créé avec succès.');
    } catch (error) {
      console.error("Erreur lors de la création de l'utilisateur:", error.message);
    }
};

export const updateUser = async (user, email, dispatch) => {
    try {
        const updatedData = user.toFirestore();

        const filteredData = cleanData(updatedData);

        if (Object.keys(filteredData).length === 0) {
            console.log('Aucune modification détectée.', filteredData);
            dispatch(setNotify({ type: false, message: 'Aucune modification à mettre à jour.' }));
            return;
        }

        console.log('Données nettoyées:', JSON.stringify(filteredData, null, 2));
        await setDoc(doc(db, 'users', email), filteredData, { merge: true });
        dispatch(setNotify({ type: true, message: 'Utilisateur mis à jour avec succès.' }));
        console.log('Utilisateur mis à jour avec succès:', filteredData);
    } catch (error) {
        console.error('Erreur lors de la mise à jour de l\'utilisateur:', error.message);
        dispatch(setNotify({ type: false, message: 'Erreur lors de la mise à jour de l\'utilisateur.' }));
    }
};

export const saveHobsSpace = async (hobsSpaceName, email) => {
    try {
        // Enregistrer les données dans le document créé
        await setDoc(doc(db, "hobs-space", hobsSpaceName), { email: email });
        console.log(`Le hobs space '${hobsSpaceName}' a été enregistré avec succès avec l'email '${email}'.`);
    } catch (error) {
        console.error('Erreur lors de l\'enregistrement du hobs space avec email:', error.message);
        throw error; // Facultatif : relancer l'erreur pour la gérer à un niveau supérieur
    }
};

export const getUserInfo = async (email) => {
    try {
        const userDocRef = doc(db, 'users', email);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const userInstance = User.fromFirestore(userData);
          console.log("Données de l'utilisateur récupérées:", userInstance, userData);
        } else {
          console.log("Aucune donnée d'utilisateur trouvée.");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération ou de la création de l'utilisateur:", error);
      }
};

export const updateUserInfo = async (email, updatedInfo, dispatch) => {
    try {
        await updateDoc(doc(db, 'users', email), updatedInfo);
        console.log('Informations utilisateur mises à jour avec succès.');
        dispatch(setNotify({type: true, message: 'Informations utilisateur mises à jour avec succès.'}));
    } catch (error) {
        console.error('Erreur lors de la mise à jour des informations de l\'utilisateur:', error.message);
        dispatch(setNotify({type: false, message: 'Erreur lors de la mise à jour des informations de l\'utilisateur.'}));
    }
};

export const deleteUserInfo = async (email, dispatch) => {
    try {
        await deleteDoc(doc(db, 'users', email));
        console.log('Document utilisateur supprimé avec succès.');
        dispatch(setNotify({type: true, message: 'Document utilisateur supprimé avec succès.'}));
    } catch (error) {
        console.error('Erreur lors de la suppression du document utilisateur:', error.message);
        dispatch(setNotify({type: false, message: 'Erreur lors de la suppression du document utilisateur.'}));
    }
};


const initializeUserStorage = async (userEmail, image) => {
    try {
        if(image){
            await uploadImageProfile(image, userEmail, "profile", "profile-image");
        }
        else {
            // Create a placeholder file (you can't directly create an empty folder in Firebase Storage)
            const placeholderFile = new Blob(['This is a placeholder file.'], { type: 'text/plain' });
            const storagePath = `${userEmail}/profile/init.txt`;
            const storageRef = ref(storage, storagePath);
            await uploadBytes(storageRef, placeholderFile);
        }

        console.log('User storage initialized for:', userEmail);
    } catch (error) {
        console.error('Error initializing user storage:', error);
        throw error;
    }
};

export const uploadImageProfile = async (file, userEmail, subfolder = '', newFileName = '') => {
    try {
        // Déterminez le nom du fichier, ajoutez un timestamp pour éviter les conflits de noms
        const baseFileName = newFileName || file.name.split('.')[0]; // Nom du fichier sans extension
        const fileExtension = file.type.split('/')[1]; 
        const timestamp = Date.now(); 
        const fileName = `${baseFileName}-${timestamp}.${fileExtension}`;
        const filePath = `${userEmail}/${subfolder}/${fileName}`;
        const storageRef = ref(storage, filePath);

        // Liste et supprime les fichiers existants avec des noms similaires
        const folderPath = `${userEmail}/${subfolder}`;
        const folderRef = ref(storage, folderPath);
        const fileList = await listAll(folderRef);

        const filesToDelete = fileList.items.filter(itemRef => {
            // Vérifie si le nom de base du fichier est contenu dans le nom du fichier existant
            return itemRef.name.includes(baseFileName) && itemRef.name !== fileName;
        });

        const deletePromises = filesToDelete.map(itemRef => deleteObject(itemRef));
        await Promise.all(deletePromises);

        // Télécharge le nouveau fichier
        const snapshot = await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(snapshot.ref);
        console.log('File uploaded successfully:', downloadURL);
        return downloadURL;
    } catch (error) {
        console.error('Error uploading file:', error);
        throw error;
    }
};

export const uploadImage = async (file, userEmail, subfolder = '', newFileName = '') => {
    try {
        // Déterminez le nom du fichier, ajoutez un timestamp pour éviter les conflits de noms
        const baseFileName = newFileName || file.name.split('.')[0]; // Nom du fichier sans extension
        const fileExtension = file.type.split('/')[1]; 
        const fileName = `${baseFileName}.${fileExtension}`;
        const filePath = `${userEmail}/${subfolder}/${fileName}`;
        const storageRef = ref(storage, filePath);

        // Liste et supprime les fichiers existants avec des noms similaires
        const folderPath = `${userEmail}/${subfolder}`;
        const folderRef = ref(storage, folderPath);
        const fileList = await listAll(folderRef);

        const filesToDelete = fileList.items.filter(itemRef => {
            // Vérifie si le nom de base du fichier est contenu dans le nom du fichier existant
            return itemRef.name.includes(baseFileName) && itemRef.name !== fileName;
        });

        const deletePromises = filesToDelete.map(itemRef => deleteObject(itemRef));
        await Promise.all(deletePromises);

        // Télécharge le nouveau fichier
        const snapshot = await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(snapshot.ref);
        console.log('File uploaded successfully:', downloadURL);
        return downloadURL;
    } catch (error) {
        console.error('Error uploading file:', error);
        throw error;
    }
};

export const getImages = async (userEmail, subfolder = '') => {
    try {
        const folderPath = `${userEmail}/${subfolder}`;
        const folderRef = ref(storage, folderPath);
        const fileList = await listAll(folderRef);
        // Vérifier s'il y a des images dans le dossier
        if (fileList.items.length === 0) {
            throw new Error("No images found in the folder.");
        }
        // Récupérer les URLs de téléchargement pour chaque élément dans la liste
        const urls = await Promise.all(fileList.items.map(async (itemRef) => {
            const downloadURL = await getDownloadURL(itemRef);
            return downloadURL;
        }));
        return urls;
    } catch (error) {
        console.error('Error fetching images:', error);
        throw error;
    }
};

export const deleteFile = async (userEmail, subfolder = '', fileNameWithoutExtension = '') => {
    try {
        const folderPath = `${userEmail}/${subfolder}`;
        console.log('Looking for file in folder:', folderPath);

        const folderRef = ref(storage, folderPath);

        // Lister tous les fichiers dans le dossier
        const fileList = await listAll(folderRef);

        // Rechercher le fichier correspondant au nom sans extension
        const fileToDelete = fileList.items.find((file) =>
            file.name.startsWith(fileNameWithoutExtension)
        );

        if (!fileToDelete) {
            console.warn(`No file found matching the name: ${fileNameWithoutExtension}`);
            return;
        }

        // Supprimer le fichier trouvé
        await deleteObject(fileToDelete);
        console.log('File deleted successfully:', fileToDelete.name);
    } catch (error) {
        console.error('Error deleting file:', error.message);
        throw error;
    }
};

export const getImageComponent = async (userEmail, folderName = '', componentId = '') => {
    try {
        // Créez le chemin vers le dossier
        const folderPath = `${userEmail}/${folderName}`;
        const folderRef = ref(storage, folderPath);
        
        // Récupérer tous les fichiers dans le dossier
        const fileList = await listAll(folderRef);
        
        // Récupérer les fichiers qui correspondent au componentId sans l'extension
        const matchingFiles = await Promise.all(
            fileList.items.map(async (itemRef) => {
                const fileName = itemRef.name.split('.')[0]; // Obtenez le nom du fichier sans l'extension
                if (fileName === componentId) {
                    const downloadURL = await getDownloadURL(itemRef);
                    return downloadURL; // Retourner l'URL du fichier correspondant
                }
                return null; // Si ce n'est pas un match, retourner null
            })
        );

        // Filtrer les résultats pour obtenir l'URL valide
        const downloadURLs = matchingFiles.filter(url => url !== null);

        if (downloadURLs.length > 0) {
            return downloadURLs[0]; // Retourner la première URL correspondante
        } else {
            throw new Error('File not found');
        }
    } catch (error) {
        console.error('Error fetching image/video:', error);
        throw error;
    }
};
