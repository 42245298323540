import React from 'react';
import './MobilePreview.css';

const MobilePreview = ({ children, bgd, img }) => {
    return (
        <div id="preview" className="fixed h-[90vh] w-6/6 mobile-preview">
            <div className="mobile-stripe top"></div>
            <div className="mobile-stripe bottom"></div>
            <div className='mobile-style z-40' style={{ backgroundColor: bgd }}>
                <div
                    className="mobile-content-image"
                    style={{
                        backgroundImage: img ? `url(${img})` : undefined,
                        backgroundColor: img ? undefined : bgd,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                    }}
                >
                    <div className='min-h-full' style={img ? {backgroundColor: 'rgba(0, 0, 0, 0.2)'} : null}>
                        <div className="mobile-content">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobilePreview;
