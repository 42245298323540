import React, { useState, useEffect } from 'react';
import InputFieldText from '../InputField/InputFieldText';
import { useUser } from '../../utils/contexts/userContext';
import './LinkMusic.css';
import { useDispatch } from 'react-redux';
import { setPage } from '../../services/store/reducers/userSlice';
import { searchSpotifyTrack } from '../../services/api/spotify';
import { searchDeezerTrack } from '../../services/api/deezer';
import { searchAppleMusicTrack } from '../../services/api/appleMusic';
import { searchYoutubeTrack } from '../../services/api/youtubeMusic';
import { getSpotifyTrackById } from '../../services/api/spotify';
import { getDeezerTrackById } from '../../services/api/deezer';
import { getAppleMusicTrackById } from '../../services/api/appleMusic';
import { getYoutubeTrackById } from '../../services/api/youtubeMusic';
import { Search } from 'lucide-react';
import AppleMusic from '../../assets/applemusic-color.png';
import YoutubeMusic from '../../assets/youtubemusic-color.png';
import Spotify from '../../assets/spotify-color.png';
import Deezer from '../../assets/deezer-color.png';

const LinkMusicEdit = ({ obj, handleCloseModal }) => {
    const { user } = useUser();
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [hoveredSong, setHoveredSong] = useState(null); // Stocke l'index de la chanson survolée
    const [selectedPlatforms, setSelectedPlatforms] = useState([]); // Plateformes sélectionnées
    const [normalizedMusic, setNormalizedMusic] = useState(null); // Données normalisées de la musique
    const dispatch = useDispatch();

    const platforms = [
        { name: 'spotify', logo: Spotify },
        { name: 'deezer', logo: Deezer },
        { name: 'applemusic', logo: AppleMusic },
        { name: 'youtubemusic', logo: YoutubeMusic },
    ];

    useEffect(() => {
        // Initialise les plateformes sélectionnées à partir de `idMedia`
        if (obj.idMedia) {
            const initialPlatforms = Object.keys(obj.idMedia);
            setSelectedPlatforms(initialPlatforms);
        }

        // Récupère les données normalisées de la musique si une plateforme avec un ID existant est configurée
        const existingPlatform = obj.idMedia && Object.keys(obj.idMedia).find((key) => obj.idMedia[key]);
        if (existingPlatform) {
            const musicId = obj.idMedia[existingPlatform];
            const fetchFunction = getTrackFunctionByPlatform(existingPlatform);

            if (fetchFunction && musicId) {
                fetchFunction(musicId)
                    .then((data) => {
                        if (data) {
                            setNormalizedMusic({
                                title: data.title,
                                artists: data.artists,
                            });
                        } else {
                            setError(`Impossible de récupérer les informations de la musique sur ${existingPlatform}.`);
                        }
                    })
                    .catch((error) => {
                        console.error(`Erreur lors de la récupération des données sur ${existingPlatform}:`, error);
                        setError(`Erreur lors de la récupération des données sur ${existingPlatform}.`);
                    });
            }
        }
    }, [obj.idMedia]);

    const togglePlatform = async (platform) => {
        setSelectedPlatforms((prev) => {
            const isSelected = prev.includes(platform);
            const updated = isSelected ? prev.filter((p) => p !== platform) : [...prev, platform];

            if (isSelected) {
                // Supprime la plateforme de `idMedia`
                const idMedia = { ...obj.idMedia };
                delete idMedia[platform];
                user.updateObjectInPageById(obj.id, { idMedia });
                dispatch(setPage(user.page));
            } else if (normalizedMusic) {
                // Recherche la musique sur la nouvelle plateforme
                const { title, artists } = normalizedMusic;
                const searchFunction = getSearchFunctionByPlatform(platform, 1);
                console.log('searchFunction', searchFunction);
                if (searchFunction) {
                    searchFunction(title, artists)
                        .then((songs) => {
                            if (songs && songs.length > 0) {
                                const song = songs[0]; // Prend le premier résultat
                                const idMedia = { ...obj.idMedia, [platform]: song.id };
                                console.log('idMedia', idMedia, song);
                                user.updateObjectInPageById(obj.id, { idMedia });
                                dispatch(setPage(user.page));
                            } else {
                                setError(`Musique introuvable sur ${platform}.`);
                            }
                        })
                        .catch((error) => {
                            console.error(`Erreur lors de la recherche sur ${platform}:`, error);
                            setError(`Erreur lors de la recherche sur ${platform}.`);
                        });
                }
            }

            return updated;
        });
    };

    const getTrackFunctionByPlatform = (platform) => {
        switch (platform) {
            case 'spotify':
                return getSpotifyTrackById;
            case 'deezer':
                return getDeezerTrackById;
            case 'applemusic':
                return getAppleMusicTrackById;
            case 'youtubemusic':
                return getYoutubeTrackById;
            default:
                return null;
        }
    };

    const getSearchFunctionByPlatform = (platform, nbTracks) => {
        switch (platform) {
            case 'spotify':
                return (title, artists) => searchSpotifyTrack(`${title} ${artists[0]}`, nbTracks);
            case 'deezer':
                return (title, artists) => searchDeezerTrack(`${title} ${artists[0]}`, nbTracks);
            case 'applemusic':
                return (title, artists) => searchAppleMusicTrack(`${title} ${artists[0]}`, nbTracks);
            case 'youtubemusic':
                return (title, artists) => searchYoutubeTrack(`${title} ${artists[0]}`, nbTracks);
            default:
                return null;
        }
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (query.trim()) {
                console.log('searching...');
                handleSearch();
                console.log('searched');
            }
        }, 200);

        return () => clearTimeout(delayDebounceFn);
    }, [query]);

    const handleSearch = async () => {
        if (selectedPlatforms.length === 0) {
            setError('Veuillez sélectionner une ou plusieurs plateformes avant de rechercher des musiques.');
            return;
        }

        setLoading(true);
        setError('');
        setResults([]);

        try {
            const promises = selectedPlatforms.map((platform) => {
                const searchFunction = getSearchFunctionByPlatform(platform, 5);
                if (searchFunction) {
                    return searchFunction(query, ['']);
                }
                return Promise.resolve([]);
            });

            const platformResults = await Promise.all(promises);

            const aggregatedResults = platformResults.flat().reduce((acc, song) => {
                const existingSong = acc.find(
                    (item) =>
                        item.title.toLowerCase() === song.title.toLowerCase() &&
                        item.artists.some((artist) =>
                            song.artists.map((a) => a.toLowerCase()).includes(artist.toLowerCase())
                        )
                );
                if (existingSong) {
                    if (!existingSong.platforms.includes(song.platform)) {
                        existingSong.platforms.push(song.platform);
                    }
                } else {
                    acc.push({ ...song, platforms: [song.platform] });
                }
                console.log('acc', acc);
                return acc;
            }, []);
            setResults(aggregatedResults);
        } catch (err) {
            setError('Une erreur est survenue lors de la recherche.');
            console.error(err);
        }

        setLoading(false);
    };


    const handleSave = (song) => {
        const idMedia = song.platforms.reduce((acc, platform) => {
            acc[platform] = song.id;
            return acc;
        }, {});
        user.updateObjectInPageById(obj.id, { idMedia });
        dispatch(setPage(user.page));
        handleCloseModal();
    };

    return (
        <>
        <div className="flex items-center flex-col justify-center w-full">
            <div className="platformSelection">
                {platforms.map(({ name, logo }) => (
                    <button
                        key={name}
                        onClick={() => togglePlatform(name)}
                        className={`platformButton ${selectedPlatforms.includes(name) ? 'selected' : ''}`}
                    >
                        <img className="w-5 h-5 object-contain object-center rounded" src={logo} alt={name} title={name} />
                    </button>
                ))}
            </div>
            <div className="searchBarWrapper">
                <InputFieldText
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Rechercher une musique..."
                    title="Rechercher"
                    type={true}
                />
                <Search className="searchIcon" />
            </div>
            {error && <p className="text-xs text-red-600 w-full text-left">{error}</p>}
            {loading && <p className="text-sm text-blue-600 w-full text-left">Chargement...</p>}
            <div className="resultsContainer">
                {results.map((song, index) => (
                    <div
                        key={index}
                        className={`resultItem ${hoveredSong === index ? 'hovered' : ''}`}
                        onMouseEnter={() => setHoveredSong(index)}
                        onMouseLeave={() => setHoveredSong(null)}
                        onClick={() => handleSave(song)}
                    >
                        <img src={song.image} alt={song.title} className="songImage" />
                        <p className="titleSingerSong">
                            <strong>{song.title}</strong>
                            {song.artists.join(', ')}
                        </p>
                        <div className="platformIcons">
                            {song.platforms.map((platform, idx) => (
                                <img
                                    key={`${platform}-${idx}`}
                                    src={platforms.find((p) => p.name === platform)?.logo}
                                    alt={platform}
                                    title={platform}
                                    className="platformIcon"
                                />
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
        <div className="containerButtonImgProfil">
            <button className="buttonImgProfilClose" onClick={handleCloseModal}>
                Annuler
            </button>
        </div>
    </>
    );
};

export default LinkMusicEdit;
