import React from 'react'
import styles from './Menu.module.css';
import AncreMenu from '../AncreMenu/AncreMenu';
import { useSelector } from 'react-redux';
import { useHobspace } from '../../../utils/contexts/hobspaceContext';
import { hexToRgb } from '../../../utils';

function Menu(props) {

    const bgdImage = useSelector(state => state.hobspace.backgroundImage);
    const {hobspace} = useHobspace();

    return (
        <div className={styles.Menu} style={bgdImage ? {backgroundColor: hexToRgb(hobspace.backgroundColor, 1)} : {backgroundColor: `rgba(255,255,255,0.15)`}}>
            {props.menu ? props.menu.map((title, index) =>
                <AncreMenu key={index} ancre={title} name={title} />
            )
            :
            null
            }
        </div>
    );
}

export default Menu;