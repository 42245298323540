import React, { useState, useEffect } from "react";
import './App.css';
import { Routes, Route, useLocation } from "react-router-dom";
import Dashboard from "./views/Dashboard/DashBoard";
import { Provider } from "react-redux";
import { store } from "./services/store/store";
import Home from "./views/Home/Home";
import Pricing from "./views/Pricing/Pricing";
import Support from "./views/Support/Support";
import Contact from "./views/Contact/Contact";
import Login from "./views/Login/Login";
import SignUp from "./views/SignUp/SignUp";
import Partners from "./views/Partners/Partners";
import { UserProvider } from "./utils/contexts/userContext";
import ScrollToAnchor from "./utils/ScrollToAncor";
import { HobspaceProvider } from "./utils/contexts/hobspaceContext";
import Hobspace from "./hobs-space/Views/Home";
import { getAllHobsSpace } from "./services/storage/storageHobspaceService";
import DynamicManifest from "./DynamicManifest";
import { capitalizeFirstLetter } from "./utils";

function App() {
  const [hobspacesID, setHobspacesID] = useState(null);
  const [currentTitle, setCurrentTitle] = useState("Hob's Space"); // État pour suivre le titre
  const location = useLocation(); 

  const getHobspacesID = async () => {
    try {
      const hobspaces = await getAllHobsSpace();
      setHobspacesID(hobspaces);
    } catch (error) {
      console.error('Erreur lors de la récupération des Hobspaces:', error);
    }
  };

  useEffect(() => {
    const path = location.pathname;

    if (path === "/") setCurrentTitle("Accueil");
    else if (path === "/dashboard") setCurrentTitle("Dashboard");
    else if (path === "/pricing") setCurrentTitle("Tarifs");
    else if (path === "/partners") setCurrentTitle("Partenaires");
    else if (path === "/support") setCurrentTitle("Support");
    else if (path === "/contact") setCurrentTitle("Contact");
    else if (path === "/login") setCurrentTitle("Connexion");
    else if (path === "/signup") setCurrentTitle("Inscription");
    else if (hobspacesID && hobspacesID.some(h => `/${h.id}` === path)) {
      const hobspace = hobspacesID.find(h => `/${h.id}` === path);
      setCurrentTitle(capitalizeFirstLetter(hobspace.id)); // Utilise l'ID du Hobspace comme titre
    } else {
      setCurrentTitle("Hob's Space"); // Titre par défaut pour les autres cas
    }
  }, [location, hobspacesID]);

  useEffect(() => {
    getHobspacesID();
  }, []);

  return (
    <Provider store={store}>
      <UserProvider>
        <div className="App">
          <ScrollToAnchor />
          <DynamicManifest title={`Hob's Space - ${currentTitle}`} />
          <Routes>
            <Route path={`/`} element={<Home />} />
            <Route path={`/dashboard`} element={<Dashboard />} />
            <Route path={`/pricing`} element={<Pricing />} />
            <Route path={`/partners`} element={<Partners />} />
            <Route path={`/support`} element={<Support />} />
            <Route path={`/contact`} element={<Contact />} />
            <Route path={`/login`} element={<Login />} />
            <Route path={`/signup`} element={<SignUp />} />
            {Array.isArray(hobspacesID) && hobspacesID.map((hobspace, index) => (
              <Route
                key={index}
                path={`/${hobspace.id}`}
                element={
                  <HobspaceProvider>
                    <Hobspace hobspaceId={hobspace.id} />
                  </HobspaceProvider>
                }
              />
            ))}
            {/* <Route path="*" element={<div>404 - Page non trouvée</div>} /> */}
          </Routes>
        </div>
      </UserProvider>
    </Provider>
  );
}

export default App;
