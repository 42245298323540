import React, { useState, useEffect } from 'react';
import "./Media.css";
import InputFieldText from '../InputField/InputFieldText';
import { useUser } from '../../utils/contexts/userContext';
import ButtonUploadMedia from '../ButtonEditImage/ButtonUploadMedia';
import { getImageComponent } from '../../services/storage/storageService';
import { useSelector, useDispatch } from 'react-redux';
import { setPage } from '../../services/store/reducers/userSlice';

const MediaEdit = ({ obj, handleCloseModal }) => {
    const { user } = useUser();
    const email = useSelector((state) => state.user.email);
    const [link, setLink] = useState(null);
    const [file, setFile] = useState(null);
    const [type, setType] = useState("image");
    const [error, setError] = useState({ status: false, message: "" });
    const [previewSrc, setPreviewSrc] = useState(null);
    const [description, setDescription] = useState(null);
    const dispatch = useDispatch();
    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp'];
    const validVideoTypes = ['video/mp4', 'video/avi', 'video/mov', 'video/mkv', 'video/webm'];

    const handleChangeLink = (newValue) => setLink(newValue);
    const handleChangeDescription = (newValue) => setDescription(newValue);

    const sentEditComponent = async () => {
        await user.updateObjectInPageById(obj.id, {
            description: description,
            link: link,
            type: type,
        });
        dispatch(setPage(user.page));
        closeModal();
    };

    const closeModal = () => {
        handleCloseModal();
        setFile(null);
        setPreviewSrc(null);
        setError({ status: false, message: "" });
    };

    useEffect(() => {
        const fetchMedia = async () => {
            if (obj) {
                setLink(obj.link);
                setDescription(obj.description);
                // Récupérer l'URL de l'image/vidéo lié à l'ID
                try {
                    const mediaUrl = await getImageComponent(email, 'Media', obj.id);
                    if (mediaUrl) {
                        // Vérifier si c'est une image ou une vidéo
                        if (obj.type === "video") {
                            getVideoThumbnail(mediaUrl);
                            console.log(mediaUrl);
                        } else if (obj.type === "image") {
                            setPreviewSrc(mediaUrl); 
                            console.log(mediaUrl);
                        }
                    }
                } catch (error) {
                    console.error("Erreur lors de la récupération des médias :", error);
                }
            }
        };

        fetchMedia();
    }, [obj, user.page]);

    const getVideoThumbnail = (videoUrl) => {
        const video = document.createElement('video');
        video.crossOrigin = "anonymous";
        video.src = videoUrl;
        video.currentTime = 1;
    
        video.onloadeddata = () => {
            try {
                const canvas = document.createElement('canvas');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                const thumbnail = canvas.toDataURL('image/png');
                setPreviewSrc(thumbnail);
            } catch (error) {
                console.error("Erreur lors de la création de la miniature vidéo :", error);
                setError({ status: true, message: "Impossible de générer la miniature vidéo." });
            }
        };
    
        video.onerror = () => {
            console.error("Erreur lors du chargement de la vidéo.");
            setError({ status: true, message: "Erreur lors du chargement de la vidéo." });
        };
    };
    
    const handleFileSelected = (selectedFile) => {
        if (!selectedFile) {
            console.error("Aucun fichier sélectionné");
            return;
        }
    
        if (previewSrc) {
            URL.revokeObjectURL(previewSrc); // Libérer l'URL précédente
        }
    
        const isImage = validImageTypes.includes(selectedFile.type);
        const isVideo = validVideoTypes.includes(selectedFile.type);
    
        if (isImage) {
            setFile(selectedFile);
            setPreviewSrc(URL.createObjectURL(selectedFile));
            setType("image");
        } else if (isVideo) {
            const video = document.createElement('video');
            video.src = URL.createObjectURL(selectedFile);
            video.currentTime = 1;
    
            video.onloadeddata = () => {
                const canvas = document.createElement('canvas');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                const thumbnail = canvas.toDataURL('image/png');
                setPreviewSrc(thumbnail);
                setFile(selectedFile);
            };
    
            video.onerror = () => {
                console.error("Erreur lors du chargement de la vidéo.");
                setError({ status: true, message: "Type de fichier non pris en charge ou erreur de chargement vidéo." });
            };
    
            setType("video");
        } else {
            console.error("Type de fichier non pris en charge.");
            setError({ status: true, message: "Type de fichier non pris en charge." });
        }
    };
       

    return (
        <>
            <div className='flex items-center flex-col justify-center w-full gap-6'>
                {obj && (
                    <>
                        <div className='flex flex-col w-full'>
                            <div className='flex flex-col w-full gap-6'>
                                <InputFieldText
                                    value={description}
                                    maxLength={256}
                                    onChange={(e) => handleChangeDescription(e.target.value)}
                                    placeholder={description}
                                    title={"Description"}
                                    type={false}
                                />
                                <InputFieldText
                                    type={true}
                                    maxLength={2048}
                                    value={link}
                                    onChange={(e) => handleChangeLink(e.target.value)}
                                    placeholder={link}
                                    title={"Lien"}
                                />
                            </div>
                        </div>
                        <div className='flex flex-col justify-center items-center w-full gap-3'>
                            <p className='InputFieldTextTitle'>Image/Vidéo</p>
                            <div className='containerEditImgComponent'>
                                {previewSrc ? (
                                    <img className='ImgEditComponent' src={previewSrc} alt="Preview" />
                                ) : (
                                    <div className='ImgEditComponent'>
                                        <i className={"fa-solid fa-image"}></i>
                                    </div>
                                )}
                                <ButtonUploadMedia 
                                    title={"Charger un média"} 
                                    folder={"Media"} 
                                    nameFile={obj.id} 
                                    setFile={handleFileSelected} 
                                    setTitle={setDescription}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className='containerButtonImgProfil'>
                <button className='buttonImgProfilClose' onClick={closeModal}>Annuler</button>
                <button className='buttonImgProfilSave' onClick={sentEditComponent}>Enregistrer</button>
            </div>
        </>
    );
};

export default MediaEdit;
