import React from 'react'
import './Menu.css';
import AncreMenu from '../AncreMenu/AncreMenu';
import { useSelector } from 'react-redux';
import { useUser } from '../../utils/contexts/userContext';
import { hexToRgb } from '../../utils';

function Menu(props) {

    const bgdImage = useSelector(state => state.user.backgroundImage);
    const {user} = useUser();
    const menu = useSelector((state) => state.user.menu);

    return (
        <div className='Menu' style={bgdImage ? {backgroundColor: hexToRgb(user.backgroundColor)} : {backgroundColor: `rgba(255,255,255,0.15)`}}>
            {menu ? menu.map((title, index) =>
                <AncreMenu key={index} ancre={title} name={title} />
            )
            :
            null
            }
        </div>
    );
}

export default Menu;