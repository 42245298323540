import React, {useState, useEffect} from 'react'
import './Support.css';
import NavigationHome from '../../Layouts/Navigation/NavigationHome/NavigationHome';
import TitleTabNavigator from '../../components/TitleTabNavigator/TitleTabNavigator';
import MetaTags from '../../components/MetaTags/MetaTags';

function Support() {

    const bgdContainer = "#ffffff";
    const colorFont = "255, 255, 255";

    useEffect(() => {
       
    }, []);
 
    return (
        <div className='Support' style={{background: bgdContainer}}>
            <MetaTags/>
            <TitleTabNavigator title={"Support"}/>
            <NavigationHome color={colorFont} bgd={bgdContainer} />
            <div className='containerTitleSupport'>
                <h1 className='titleSupport'>Support</h1>
            </div>
         </div>
    );
}

export default Support;