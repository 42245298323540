import { emailIsValid, hobsSpaceIsValid, passwordIsValid } from "../store/reducers/authSlice";
import { login, setEmail, setImages, setBackgroundImage, setBannerImage } from "../store/reducers/userSlice";
import { auth, db } from "../firebase/firebase.config";
import { doc, getDoc, getDocs, query, where, collection } from 'firebase/firestore';
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { createUser as createFirestoreUser, getUserInfo, saveHobsSpace, getImages } from "../storage/storageService";
import { 
    createUserWithEmailAndPassword as firebaseCreateUserWithEmailAndPassword, 
    signInWithPhoneNumber, 
    GoogleAuthProvider, 
    OAuthProvider ,
    onAuthStateChanged
} from "firebase/auth";
import User from "../storage/user";

// Fonction d'inscription avec email et mot de passe
export const createUserWithEmailAndPassword = async (email, password, space, userInfo, dispatch, image) => {
    try {
        const userCredential = await firebaseCreateUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        await createFirestoreUser(user, userInfo, image);
        await saveHobsSpace(space, email);
        dispatch(login(true));
        console.log('Utilisateur créé');
        return true;
    } catch (error) {
        console.error('Erreur lors de la création de l\'utilisateur:', error); 
        dispatch(login(false));
        return false;
    }
    
};

// Fonction d'inscription avec numéro de téléphone
export const createUserWithPhoneNumber = async (phoneNumber) => {
    try {
        const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber);
        const verificationCode = prompt('Entrez le code de vérification envoyé par SMS :');
        const userCredential = await confirmationResult.confirm(verificationCode);
        const user = userCredential.user;
        console.log('Utilisateur inscrit avec succès:', user);
    } catch (error) {
        console.error('Erreur lors de l\'inscription par téléphone:', error);
    }
};

// Fonction pour envoyer un e-mail de vérification
export const sendEmailVerification = async () => {
    const user = auth.currentUser;
    if (user) {
        try {
            await user.sendEmailVerification();
            console.log('E-mail de vérification envoyé à', user.email);
        } catch (error) {
            console.error('Erreur lors de l\'envoi de l\'e-mail de vérification:', error);
        }
    }
};

// Fonction de connexion avec Google
export const signInWithGoogle = async () => {
    try {
        const provider = new GoogleAuthProvider();
        await auth.signInWithPopup(provider);
        console.log('Connexion réussie avec Google');
    } catch (error) {
        console.error('Erreur lors de la connexion avec Google:', error);
    }
};

// Fonction de connexion avec Apple
export const signInWithApple = async () => {
    try {
        const provider = new OAuthProvider('apple.com');
        await auth.signInWithPopup(provider);
        console.log('Connexion réussie avec Apple');
    } catch (error) {
        console.error('Erreur lors de la connexion avec Apple:', error);
    }
};

const getImageProfil = async (dispatch, email) => {
    try {
        const img = await getImages(email, "profile");
        if (img !== undefined) {
            dispatch(setImages(img));
        }
    } catch (error) {
        console.error("Error fetching profile image:", error.message);
    }

    try {
        const bgdImg = await getImages(email, "backgroundImage");
        if (bgdImg !== undefined) {
            dispatch(setBackgroundImage(bgdImg));
        }
    } catch (error) {
        console.error("Error fetching background image:", error.message);
    }

    try {
        const bannerImg = await getImages(email, "banner");
        if (bannerImg !== undefined) {
            dispatch(setBannerImage(bannerImg));
        }
    } catch (error) {
        console.error("Error fetching banner image:", error.message);
    }
};

export const listenToAuthState = (dispatch, image) => {
    return onAuthStateChanged(auth, (user) => {
      if (user) {
        getUserInfo(user.email);
        dispatch(login(true));
        dispatch(setEmail(user.email));
        if(image === undefined) {
            console.log(image);
            getImageProfil(dispatch, user.email);
        }
        console.log(image);
        console.log("Utilisateur connecté");
        return true;
      } else {
        // L'utilisateur n'est pas connecté
        console.log("Utilisateur non connecté");
        dispatch(login(false));
        return false;
      }
    });
};  

// Fonction de vérification de la force du mot de passe
export const checkPasswordStrength = (password, dispatch) => {
    if (password.length < 8) {
        dispatch(passwordIsValid({ isValid: false, message: "Le mot de passe doit contenir au moins 8 caractères." }));
        return false;
    }

    if (!/[A-Z]/.test(password)) {
        dispatch(passwordIsValid({ isValid: false, message: "Le mot de passe doit contenir au moins une lettre majuscule." }));
        return false;
    }

    if (!/[|~?@[\]^_!"#$%&'()*+,\-./:;{}<>=\\]/.test(password)) {
        dispatch(passwordIsValid({ isValid: false, message: "Le mot de passe doit contenir au moins un caractère spécial." }));
        return false;
    }

    if (!/\d/.test(password)) {
        dispatch(passwordIsValid({ isValid: false, message: "Le mot de passe doit contenir au moins un chiffre." }));
        return false;
    }

    dispatch(passwordIsValid({ isValid: true, message: "" }));
    return true;
};

export const logout = async (dispatch) => {
    const auth = getAuth();
    try {
        await signOut(auth);
        //User.resetUser();
        dispatch(login(false));
        dispatch(setEmail(""));
        console.log("Utilisateur déconnecté");
    } catch (error) {
        console.error("Erreur lors de la déconnexion:", error);
    }
};

export const loginWithEmailOrHobsSpace = async (input, password, dispatch, navigate) => {
    const isAnEmail = await checkEmailExist(input, dispatch);
    let email = input;
    // Vérifiez si l'entrée est un email
    if (!isAnEmail) {
        // Si ce n'est pas un email, vérifiez si c'est un hobs space
        const isAHobsSpace = await checkHobsSpaceExists(input, dispatch);
        
        if (!isAHobsSpace) {
            const hobsSpaceDocRef = doc(db, 'hobs-space', input);
            const hobsSpaceDocSnap = await getDoc(hobsSpaceDocRef);
            email = hobsSpaceDocSnap.data().email;
            dispatch(emailIsValid({ isValid: true, message: "" }));
        } else {
            // Si ce n'est ni un email valide ni un hobs space valide
            dispatch(emailIsValid({ isValid: false, message: "Entrer un email valide ou un hobs space valide." }));
            return;
        }
    }
    dispatch(emailIsValid({ isValid: true, message: "" }));

    try {
        const auth = getAuth();
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        getUserInfo(email);
        navigate("/");
    } catch (error) {
        console.error("Erreur lors de la connexion:", error);
        dispatch(passwordIsValid({ isValid: false, message: "Entrer un mot de passe valide." }));
    }
};

// Fonction de vérification si la chaîne est un email ou autre
export const checkEmail = async (input, dispatch) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(input)) {
        dispatch(emailIsValid({ isValid: false, message: "Entrer un email valide." }));
        return false;
    }

    try {
        // Rechercher si un document avec cet email existe dans 'users'
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('__name__', '==', input));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            console.error('Un utilisateur avec cet email existe déjà.');
            dispatch(emailIsValid({ isValid: false, message: "Cette email existe déjà." }));
            return false;
        }

        dispatch(emailIsValid({ isValid: true, message: "" }));
        return true;
    } catch (error) {
        console.error('Erreur lors de la vérification de l\'email:', error.message);
        throw error;
    }
};

// Fonction de vérification si la chaîne est un email ou autre
export const checkEmailExist = async(input, dispatch) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const userDocRef = doc(db, 'users', input);
    const userDocSnap = await getDoc(userDocRef);

    if (emailRegex.test(input)) {
        if (userDocSnap.exists()) {
            console.error('Un utilisateur avec cet email existe déjà.');
            dispatch(emailIsValid({ isValid: false, message: "Cette email existe déjà."}));
            return true;
        }
        else {
            dispatch(emailIsValid({ isValid: true, message: "" }));
            return false;
        }
        
    } else {
        dispatch(emailIsValid({ isValid: false, message: "Entrer un email valide." }));
        return false;
    }
};

export const checkHobsSpaceExists = async (hobsSpaceName, dispatch) => {
    try {
        const hobsSpaceDocRef = doc(db, 'hobs-space', hobsSpaceName);
        const hobsSpaceDocSnap = await getDoc(hobsSpaceDocRef);

        if (hobsSpaceDocSnap.exists()) {
            dispatch(hobsSpaceIsValid({
                isValid: false,
                message: `Le hobs space '${hobsSpaceName}' existe déjà.`,
                hobspace: ""
            }));
            return false;
        }

        dispatch(hobsSpaceIsValid({
            isValid: true,
            message: "",
            hobspace: hobsSpaceName
        }));
        return true;
    } catch (error) {
        console.error('Erreur lors de la vérification de l\'existence du hobs space:', error.message);
        throw error;
    }
};