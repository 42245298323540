import React, { useState, useEffect } from 'react';
import { useUser } from '../../utils/contexts/userContext';
import "./VideoCollection.css";
import InputFieldText from '../InputField/InputFieldText';
import { links } from '../../utils';
import { useDispatch } from 'react-redux';
import { setPage } from '../../services/store/reducers/userSlice';

const VideoCollectionEdit = ({ obj, handleCloseModal }) => {

    const [titleComponent, setTitleComponent] = useState("");
    const [count, setCount] = useState(0);
    const {user} = useUser();
    const dispatch = useDispatch();

    const increment = () => {
        if (count < 50) {
            setCount(count + 1);
        }
    };

    const decrement = () => {
        if (count > 1) {
            setCount(count - 1);
        }
    };

    const addLinkYTB = () => {
        const newLink = links.find(link => link.name === "youtube");
    
        if (newLink) {
            const updatedLink = { ...newLink, link: titleComponent }; // Modifie l'URL ici
            const updatedLinks = [...user.links.filter(link => link.name !== "youtube"), updatedLink];
            
            user.updateLink(updatedLinks);
        } else {
            console.log("Lien YouTube non trouvé dans la liste.");
        }
    }  

    const handleChangeTitle = (newValue) => {
        setTitleComponent(newValue);
    };

    const sentEditComponent = () => {
        user.updateObjectInPageById(obj.id, {nbVideo: count});
        dispatch(setPage(user.page));
        handleCloseModal(); 
        addLinkYTB();
    }

    useEffect(() => {
        setCount(obj.nbVideo);
        if (user.links && user.links.find(link => link.name === "youtube")) {
            const ytb = user.links.find(link => link.name === "youtube");
            setTitleComponent(ytb.link);
        }
    }, [user, obj]);

    return (
        <>
        <div className="flex items-start flex-col justify-center w-full gap-6">
            <InputFieldText value={titleComponent} maxLength={256} onChange={(e) => handleChangeTitle(e.target.value)} placeholder={titleComponent === "" ? "https://youtube.com/channel" : titleComponent} title={"Lien de la chaine Youtube"} type={true} />    
            <div className="flex items-center space-x-3 p-2 border border-gray-300 rounded-lg">
                {/* Bouton de décrément */}
                <button
                    onClick={decrement}
                    className={`font-bold py-2 px-4 rounded transition duration-200 flex items-center justify-center`}
                    disabled={count === 1}
                >
                    {/* Moins */}
                    <div className={`relative w-4 h-0.5 flex items-center justify-center rounded-full ${count === 1 ? 'bg-gray-400 cursor-not-allowed' : 'bg-black hover:bg-gray-700 text-white'}`}></div>
                </button>

                {/* Affichage du compteur */}
                <span className="text-lg font-bold">{count}</span>

                {/* Bouton d'incrément */}
                <button
                    onClick={increment}
                    className={`font-bold py-2 px-4 rounded transition duration-200 flex items-center justify-center h-8 w-8`}
                    disabled={count === 50}
                >
                    {/* Plus */}
                    <div className="relative flex items-center justify-center">
                        <div className={`absolute w-4 h-0.5 rounded-full ${count === 50 ? 'bg-gray-400 cursor-not-allowed' : 'bg-black hover:bg-gray-700'}`}></div>
                        <div className={`absolute w-0.5 h-4 rounded-full ${count === 50 ? 'bg-gray-400 cursor-not-allowed' : 'bg-black hover:bg-gray-700'}`}></div>
                    </div>
                </button>
            </div>
        </div>
        <div className='containerButtonImgProfil'>
            <button className='buttonImgProfilClose' onClick={handleCloseModal}>Annuler</button>
            <button className='buttonImgProfilSave' onClick={sentEditComponent}>Enregistrer</button>
        </div>
        </>
    );
};

export default VideoCollectionEdit;
