import axios from 'axios';

const IdClient = process.env.REACT_APP_CLIENT_ID_SPOTIFY;
const clientSecret = process.env.REACT_APP_SECRET_SPOTIFY;

// Configuration pour l'authentification Spotify
const authData = {
    grant_type: 'client_credentials',
    client_id: IdClient,
    client_secret: clientSecret,
};

// Fonction pour obtenir le token d'accès
const getSpotifyAccessToken = async () => {
    try {
        const res = await axios.post('https://accounts.spotify.com/api/token', new URLSearchParams(authData), {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });
        return res.data.access_token;
    } catch (error) {
        console.error('Erreur lors de la récupération du token Spotify:', error);
        throw new Error('Impossible de récupérer le token Spotify.');
    }
};

// Fonction pour récupérer l'ID d'un artiste à partir de l'URL personnalisée
const getSpotifyArtistIdFromCustomUrl = (user) => {
    const customUrl = user.links.find(link => link.name === "spotify")?.link;
    if (customUrl && customUrl.includes('/artist/')) {
        return customUrl.split('/artist/')[1].split('?')[0]; // Extraire l'ID après '/artist/'
    }
    return null;
};

// Fonction pour appeler l'API Spotify
const fetchFromSpotify = async (endpoint, accessToken) => {
    try {
        const response = await axios.get(`https://api.spotify.com/v1/${endpoint}`, {
            headers: { Authorization: `Bearer ${accessToken}` },
        });
        return response.data;
    } catch (error) {
        console.error(`Erreur lors de l'appel à l'API Spotify (${endpoint}):`, error);
        return null;
    }
};

// Fonction pour rechercher une musique
export const searchSpotifyTrack = async (query, limit = 5) => {
    const token = await getSpotifyAccessToken(); // Fonction pour récupérer le token
    const response = await fetch(
        `https://api.spotify.com/v1/search?q=${encodeURIComponent(query)}&type=track&limit=${limit}`,
        {
            headers: { Authorization: `Bearer ${token}` },
        }
    );
    const data = await response.json();
    // Normalisation des résultats
    return data.tracks.items.map(track => ({
        title: track.name,
        artists: track.artists.map(artist => artist.name),
        image: track.album.images[0]?.url || '', // Utilise la première image disponible
        link: track.external_urls.spotify,
        id: track.id,
        platform: 'spotify',
    }));
};

export const getSpotifyTrackById = async (id) => {
    const token = await getSpotifyAccessToken(); // Fonction pour obtenir le token Spotify
    const response = await fetch(
        `https://api.spotify.com/v1/tracks/${id}`,
        {
            headers: { Authorization: `Bearer ${token}` },
        }
    );
    const data = await response.json();
    return {
        title: data.name,
        artists: data.artists.map(artist => artist.name),
        image: data.album.images[0]?.url || '',
        link: data.external_urls.spotify,
        preview: data.preview_url || null, // Ajout du lien de prévisualisation
        platform: 'spotify',
    };
};

// Fonction pour récupérer les informations d'un artiste
export const getSpotifyArtistInfo = async (user) => {
    const accessToken = await getSpotifyAccessToken();
    const artistId = getSpotifyArtistIdFromCustomUrl(user);
    if (!artistId) return null;
    return fetchFromSpotify(`artists/${artistId}`, accessToken);
};

// Fonction pour récupérer les musiques d'un artiste (top 10)
export const getSpotifyTopTracks = async (user) => {
    const accessToken = await getSpotifyAccessToken();
    const artistId = getSpotifyArtistIdFromCustomUrl(user);
    if (!artistId) return null;
    return fetchFromSpotify(`artists/${artistId}/top-tracks?market=FR`, accessToken);
};

// Fonction pour récupérer les albums d'un artiste
export const getSpotifyAlbums = async (user, limit = 10) => {
    const accessToken = await getSpotifyAccessToken();
    const artistId = getSpotifyArtistIdFromCustomUrl(user);
    if (!artistId) return null;
    return fetchFromSpotify(`artists/${artistId}/albums?limit=${limit}`, accessToken);
};

// Fonction pour récupérer les musiques d'un album
export const getSpotifyAlbumTracks = async (albumId, limit = 10) => {
    const accessToken = await getSpotifyAccessToken();
    return fetchFromSpotify(`albums/${albumId}/tracks?limit=${limit}`, accessToken);
};

// Fonction pour rechercher des podcasts
export const searchSpotifyPodcasts = async (query) => {
    const accessToken = await getSpotifyAccessToken();
    return fetchFromSpotify(`search?q=${encodeURIComponent(query)}&type=show&limit=10`, accessToken);
};

// Fonction pour récupérer les podcasts d'un créateur
export const getSpotifyCreatorPodcasts = async (creatorId) => {
    const accessToken = await getSpotifyAccessToken();
    return fetchFromSpotify(`shows/${creatorId}`, accessToken);
};

// Fonction pour récupérer les épisodes d'un podcast (dix derniers)
export const getSpotifyPodcastEpisodes = async (podcastId, limit = 10) => {
    const accessToken = await getSpotifyAccessToken();
    return fetchFromSpotify(`shows/${podcastId}/episodes?limit=${limit}`, accessToken);
};
